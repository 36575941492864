export default {
  readOnly: {
    noneLabel: {
      es: 'Ninguno',
      en: 'None',
      pt: 'Nenhum'
    },
    noneLabelCondition: {
      es: 'No se agregó ninguna condición',
      en: 'No condition was added',
      pt: 'Nenhuma condição foi adicionada'
    }
  },
  templateGroupOptions: {
    placeholder: {
      es: 'Elija el grupo de plantillas',
      en: 'Choose the template group',
      pt: 'Escolha o grupo de modelos'
    },
    galileo_api: {
      es: 'Galileo Api',
      en: 'Galileo Api',
      pt: 'Galileo Api'
    },
    welcome: {
      es: 'Bienvenido',
      en: 'Welcome',
      pt: 'Bem-vindo',
    }
  },
  slideIntent: {
    es: 'Diferencia mínima entre confianza de intenciones',
    en: 'Minimum difference between confidence of intentions',
    pt: 'Diferença mínima entre a confiança das intenções'
  },
  loading: {
    es: 'Cargando',
    en: 'Loading',
    pt: 'Carregando'
  },
  title: {
    es: 'Bot maker',
    en: 'Bot maker',
    pt: 'Bot maker'
  },
  accept: {
    es: 'Aceptar',
    en: 'Accept',
    pt: 'Aceitar'
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  errorTitle: {
    es: 'Oops',
    en: 'Oops',
    pt: 'Oops'
  },
  formWithError: {
    es: 'El formulario tiene errores',
    en: 'The form has errors',
    pt: 'O formulário tem erros'
  },
  errorName: {
    es: 'Ya existe un bot con el mismo nombre.',
    en: 'A bot with the same name already exists.',
    pt: 'Já existe um bot com o mesmo nome.'
  },
  error: {
    es: 'Algo salio mal. Por favor intenta de vuelta mas tarde',
    en: 'Something went wrong. Please try again later',
    pt: 'Algo deu errado. Por favor, tente de novo mais tarde'
  },
  botsView: {
    title: {
      es: 'Chatbots',
      en: 'Chatbots',
      pt: 'Chatbots'
    },
    description: {
      es:
        'Aquí se muestran todos los bots de su cuenta. Para crear uno nuevo, haga click en "Nuevo bot" y siga los pasos.',
      en:
        'Here you can see all your bots. To create a new one click on "New bot" and follow the steps.',
      pt:
        'Aqui você pode ver todos os seus bots. Para criar um novo clique em "Novo bot" e seguir os passos.'
    },
    botList: {
      add: {
        es: 'Nuevo bot',
        en: 'New bot',
        pt: 'Novo bot'
      }
    },
    categories: {
      es: 'Categorías',
      en: 'Categories',
      pt: 'Categorias'
    },
    chanels: {
      es: 'Canales',
      en: 'Channels',
      pt: 'Canais'
    },
    category: {
      es: 'Categoría',
      en: 'Category',
      pt: 'Categoria'
    },
    search: {
      es: 'Buscar',
      en: 'Search',
      pt: 'Pesquisar'
    },
    filters: {
      es: 'Filtros',
      en: 'Filter',
      pt: 'Filtro'
    },
    edit: {
      es: 'Editar',
      en: 'Edit',
      pt: 'Editar'
    },
    notifications: {
      deleteBotSuccess: {
        es: 'Tu bot fue borrado exitosamente',
        en: 'Your bot has been deleted successfully.',
        pt: 'Seu bot foi deletado com sucesso.'
      },
      deleteBotError: {
        es: 'No hemos podido borrar tu bot, intenta mas tarde.',
        en: "We couldn't delete your bot. Try again later.",
        pt: 'Não foi possível deletar seu bot, tente novamente mais tarde.'
      }
    },
    tooltip: {
      test: {
        es: 'Test bot',
        en: 'Test bot',
        pt: 'Test bot'
      },
      testMobile: {
        es: 'Probar bot. Necesitas al menos 2 condiciones.',
        en: 'Test bot. You will need at least 2 conditions',
        pt: 'Você precisará de pelo menos 2 condições'
      },
      edit: {
        es: 'Editar bot',
        en: 'Edit bot',
        pt: 'Editar bot'
      },
      delete: {
        es: 'Borrar bot',
        en: 'Delete bot',
        pt: 'Apagar bot'
      }
    },
    deleteBotWarning: {
      title: {
        es: 'Atención!',
        en: 'Warning!',
        pt: 'Aviso!'
      },
      text: {
        es:
          'Estas por borrar tu bot, esta acción es irreversible. ¿Estás seguro?',
        en:
          'You are about to delete your bot, this action is irreversible. Are you sure?',
        pt:
          'Você está prestes a apagar seu bot, esta ação é irreversível. Tem certeza?'
      }
    },
  },
  editBot: {
    copyScript: {
      es: 'Copiar',
      en: 'Copy',
      pt: 'Copiar'
    },
    previewBot: {
      es: 'Previsualizar',
      en: 'Preview',
      pt: 'Visualização'
    },
    title: {
      es: 'Editar bot',
      en: 'Edit bot',
      pt: 'Editar bot'
    },
    legacyBot: {
      title: {
        es: 'El bot no puede ser editado.',
        en: 'The bot cannot be edited.',
        pt: 'O bot não pode ser editado.'
      }
    },
    saveBot: {
      es: 'Guardar cambios',
      en: 'Save changes',
      pt: 'Salvar as mudanças'
    },
    verifyToken: {
      es: 'Token de verificación',
      en: 'Verify token',
      pt: 'Token de verificação'
    },
    accessToken: {
      es: 'Token de acceso',
      en: 'Access token',
      pt: 'Token de acesso'
    },
    saveApiKey: {
      es: 'Enviar',
      en: 'Send',
      pt: 'Enviar'
    },
    doneApiKey: {
      es: 'APIKey enviada exitosamente!',
      en: 'APIKey successfully sent!',
      pt: 'APIKey foi enviada com sucesso!'
    },
    notDoneApiKey: {
      es:
        'Ha ocurrido un error al enviar la APIKey. Por favor, intente nuevamente.',
      en: 'An error has ocurred while sending APIKey. Pleas, try again.',
      pt: 'Um erro ocorreu ao enviar APIKey.'
    },
    notApiKey: {
      es: 'Ingrese APIKey',
      en: 'Enter APIKey',
      pt: 'Digite APIKey'
    },
  },
  addBot: {
    view: {
      es: 'Vista',
      en: 'View',
      pt: 'Vista'
    },
    answersView: {
      es: 'Vista de respuestas',
      en: 'Answers view',
      pt: 'Vista de respostas'
    },
    widgetDefault: {
      chat: {
        message_placeholder: {
          es: 'Escriba su pregunta...',
          en: 'Write your question...',
          pt: 'Escreva sua pergunta...'
        },
        datemessage_placeholder: {
          es: 'Seleccione fecha',
          en: 'Select date',
          pt: 'Seleccione fecha'
        },
        noLocationPermissionMessage: {
          es: 'No fue posible obtener su ubicación, ingresela manualmente.',
          en: 'Unable to get your location, please enter it manually.',
          pt: 'Não foi possível obter sua localização, insira-o manualmente.'
        },
      },
      header: {
        title: {
          es: 'Asistente Virtual',
          en: 'Virtual Assistant',
          pt: 'Assistente Virtual'
        }
      },
      presentation: {
        text: {
          es: '¿Necesitas ayuda?',
          en: 'Do you need help?',
          pt: 'Precisa de ajuda?'
        }
      },
      welcome_message: {
        text: {
          es: 'Hola, soy tu asistente virtual. ¿Como puedo ayudarte?',
          en: "Hi, I'm your virtual assistant. How can I help you?",
          pt: 'Olá, sou seu assistente virtual. Como posso ajudar?'
        }
      }
    },
    channelConfig: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo'
    },
    fieldShowWhenActive: {
      es: 'Este campo estará disponible cuando el canal este activo.',
      en: 'This field will be available when the channel is active.',
      pt: 'Este campo estará disponível quando o canal estiver ativo.'
    },
    active: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo'
    },
    fieldError: {
      es: 'Por favor ingrese un valor para este campo.',
      en: 'Please enter a value for this field.',
      pt: 'Por favor, insira um valor para este campo.'
    },
    version: {
      es: 'Version',
      en: 'Versión',
      pt: 'Versão'
    },
    widget: {
      colors: {
        colors: {
          es: 'Colores',
          en: 'Colors',
          pt: 'Cores'
        },
        text: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        background: {
          es: 'Fondo',
          en: 'Background',
          pt: 'Fundo'
        },
        thump: {
          es: 'Thump',
          en: 'Thump',
          pt: 'Thump'
        },
        links: {
          es: 'Enlaces',
          en: 'Links',
          pt: 'Links'
        },
        border: {
          es: 'Bordes',
          en: 'Borders',
          pt: 'Borda'
        },
        header: {
          es: 'Encabezado',
          en: 'Header',
          pt: 'Cabeçalho'
        },
        botMessages: {
          es: 'Colores de mensajes del bot',
          en: 'Bot messages colors',
          pt: 'Cores das mensagens do bot'
        },
        userMessages: {
          es: 'Colores de mensajes de ususarios',
          en: 'User messages colors',
          pt: 'Cores da mensagem do usuário'
        },
        buttons: {
          es: 'Color de los botones',
          en: 'Buttons colors',
          pt: 'Cores dos botões'
        },
        feedback: {
          es: 'Color de retroalimentación',
          en: 'Feedback color',
          pt: 'Cor do feedback'
        }
      },
      desktopMobile: {
        es: 'Especificaciones para burbuja de tamaño grande',
        en: 'Specifications for the big bubble',
        pt: 'Especificações para bolha de tamanho grande'
      },
      presentation: {
        es: '¿Cómo se presentará el widget?',
        en: 'How will the widget be presented?',
        pt: 'Como o widget será apresentado?'
      },
      scriptBot: {
        es: 'Script',
        en: 'Script',
        pt: 'Script'
      },
      scriptVersion: {
        es: 'Versión',
        en: 'Version',
        pt: 'Versão'
      },
      customCSS: {
        es: 'CSS personalizado',
        en: 'Custom CSS',
        pt: 'CSS customizado'
      },
      customCSSError: {
        es: 'El código CSS no es válido, por favor verifíquelo.',
        en: 'CSS code is not valid, please check.',
        pt: 'O código CSS não é válido, por favor verifique.'
      },
      copy: {
        script: {
          es: 'Copiar Script',
          en: 'Copy Script',
          pt: 'Copiar Script'
        },
        link: {
          es: 'Copiar Link',
          en: 'Copy Link',
          pt: 'Copiar Link'
        }
      },
      messageSize: {
        es: 'Ancho mensaje del bot',
        en: 'Bot message width',
        pt: 'Largura da mensagem de bot'
      },
      fontSizes: {
        containerSubtitleText: {
          es: 'Tamaño texto del Chat (px)',
          en: 'Chat font size(px)',
          pt: 'Tamanho texto do Chat (px)'
        },
        containerSubtitleButton: {
          es: 'Tamaño texto botón (px)',
          en: 'Button font size (px)',
          pt: 'Tamanho fonte do botão (px)'
        },
        containerTitle: {
          es: 'Ajustes de fuente',
          en: 'Font settings',
          pt: 'Configuração do tipo de letra'
        },
        containerSubtitle: {
          es: 'Tamaño (px)',
          en: 'Font size (px)',
          pt: 'Tamanho (px)'
        },
        presentation: {
          es: 'Presentación',
          en: 'Presentation',
          pt: 'Apresentação'
        },
        header: {
          title: {
            es: 'Título Header',
            en: 'Header Title',
            pt: 'Título do cabeçalho'
          },
          subtitle: {
            es: 'Subtítulo Header',
            en: 'Header Subtitle',
            pt: 'Legenda do cabeçalho'
          }
        },
        chat: {
          es: 'Chat',
          en: 'Chat',
          pt: 'Chat'
        },
        placeholder: {
          es: 'Placeholder',
          en: 'Placeholder',
          pt: 'Placeholder'
        },
        button: {
          es: 'Botones',
          en: 'Buttons',
          pt: 'Botões'
        }
        // Will have an single implementation (Per card)
        // card: {
        //   title: '0.85rem',
        //   subtitle: '0.85rem',
        //   button: '14px'
        // }
      },
      disable: {
        es: 'Deshabilitar',
        en: 'Disable',
        pt: 'Desativar'
      },
      enable: {
        es: 'Habilitar',
        en: 'Enable',
        pt: 'Habilitar'
      },
      medium: {
        es: 'Medio',
        en: 'Medium',
        pt: 'Médio'
      },
      large_width: {
        es: 'Largo',
        en: 'Large',
        pt: 'Grande'
      },
      large: {
        es: 'Grande',
        en: 'Large',
        pt: 'Grande'
      },
      small: {
        es: 'Pequeño',
        en: 'Small',
        pt: 'Pequeno'
      },
      restoreDefault: {
        es: 'Restaurar',
        en: 'Restore Default',
        pt: 'Restaurar o padrão'
      },
      position: {
        es: 'Posición',
        en: 'Position',
        pt: 'Posição',
        bottom: {
          es: 'Inferior',
          en: 'Bottom',
          pt: 'Inferior',
        },
        right: {
          es: 'Derecha',
          en: 'Right',
          pt: 'Dereita'
        }
      },
      width: {
        es: 'Ancho',
        en: 'Width',
        pt: 'Largura'
      },
      height: {
        es: 'Altura',
        en: 'Height',
        pt: 'Altura'
      },
      borderRadius: {
        es: 'Radio del borde',
        en: 'Border Radius',
        pt: 'Rádio de borda',
        corner: {
          bottomLeft: {
            es: 'Inferior izquierdo',
            en: 'Bottom Left',
            pt: 'Inferior esquerdo',
          },
          bottomRight: {
            es: 'Inferior derecho',
            en: 'Bottom Right',
            pt: 'Inferior direito',
          },
          topLeft: {
            es: 'Superior izquierdo',
            en: 'Top Left',
            pt: 'Superior esquerdo',
          },
          topRight: {
            es: 'Superior derecho',
            en: 'Top Right',
            pt: 'Superior dereito',
          }
        }
      },
      imageSize: {
        es: 'Medidas de la burbuja (px)',
        en: 'Bubble measures (px)',
        pt: 'Medidas de bolha'
      },
      desktop: {
        es: 'Escritorio',
        en: 'Desktop',
        pt: 'Desktop'
      },
      mobile: {
        es: 'Móvil',
        en: 'Mobile',
        pt: 'Móvel'
      },
      tooltip: {
        customCSS: {
          es:
            'Sobreescribe el CSS de la ventana de chat',
          en:
            'Overrides the CSS of the chat window',
          pt:
            'Substitui o CSS da janela de bate-papo'
        },
        script: {
          es: 'Copia este código en tu página para agregar el widget del bot',
          en: 'Copy this code into your page to add the bot widget',
          pt: 'Copiar este código em sua página para adicionar o widget bot'
        },
        scriptVersion: {
          es:
            'Puedes seleccionar una versión específica para probar en un ambiente de pre-producción. Recuerda que en este caso el bot siempre utilizará la versión pre fijada, independientemente de qué versión está en vivo',
          en:
            'You can select a specific version to test in a pre-production environment. Remember that in this case the bot will always use the pre-set version, regardless of which version is live',
          pt:
            'Você pode selecionar uma versão específica para testar em um ambiente de pré-produção. Lembre-se que neste caso o bot sempre usará a versão pré-configurada, independente de qual versão está ativa'
        },
        padding: {
          es: 'Espacio entre la imagen y el borde de la burbuja (px)',
          en: 'Space between the image and the bubble border (px)',
          pt: 'Espaço entre a imagem e a borda da bolha (px)'
        },
        reload: {
          es:
            'Habilitar el botón de reload ↻ en el widget para reiniciar el chat con un nuevo mensaje',
          en:
            'Enable reload button ↻ on the widget to restart chat with a new message',
          pt:
            'Ativar o botão de recarga no widget para reiniciar o chat com uma nova mensagem'
        },
        enableFiles: {
          es: 'Los usuarios sólo podrán enviar archivos mientras hablan con un agente.',
          en: 'Users will be able to send files only while talking to an agent.',
          pt: 'Os usuários poderão enviar arquivos somente enquanto conversam com um agente.'
        }
      },
      padding: {
        title: {
          es: 'Padding(px)',
          en: 'Padding (px)',
          pt: 'Padding (px)'
        },
        top: {
          es: 'Arriba',
          en: 'Top',
          pt: 'Acima',
        },
        right: {
          es: 'Derecha',
          en: 'Right',
          pt: 'Direito',
        },
        left: {
          es: 'Izquierda',
          en: 'Left',
          pt: 'Esquerda',
        },
        bottom: {
          es: 'Abajo',
          en: 'Bottom',
          pt: 'Abaixo',
        }
      },
      bubbleTitle: {
        es: 'Burbuja del Widget',
        en: 'Widget bubble',
        pt: 'Bolha de widget',
      },
      webPanelTitle: {
        es: 'Personaliza la apariencia de Konecta',
        en: 'Customize appearance for Konecta',
        pt: 'Personalize a aparência do Konecta',
      },
      headerTitle: {
        es: 'Encabezado',
        en: 'Header',
        pt: 'Cabeçalho'
      },
      chatAreaTitle: {
        es: 'Area de chat',
        en: 'Chat area',
        pt: 'Área de chat'
      },
      endChatTextButton: {
        es: 'Texto del botón',
        en: 'Text for the button',
        pt: 'Texto para o botão'
      },
      endChatConfirmationMessage: {
        es: 'Mensaje de confirmación',
        en: 'Confirmation message',
        pt: 'Mensagem de confirmação'
      },
      endChatMessageTextTooltip: {
        es: 'Se mostrará una notificación interna al agente que estaba hablando con el usuario, una vez que éste finalice el chat.',
        en: 'An internal notification will be displayed to the agent who was talking with the user, once the user ends the chat.',
        pt: 'Uma notificação interna será exibida para o agente que estava conversando com o usuário, assim que o usuário encerrar o bate-papo.'
      },
      textRequired: {
        es: 'El campo es obligatorio.',
        en: 'The field is required.',
        pt: 'O campo é obrigatório.'
      },
      textFieldsRequired: {
        es: 'Hay campos obligatorios que no se han rellenado.',
        en: 'There are mandatory fields that have not been filled in.',
        pt: 'Há campos obrigatórios que não foram preenchidos.'
      },
      default: {
        endChatText: {
          es: 'Finalizar chat',
          en: 'End chat',
          pt: 'Encerrar o bate-papo'
        },
        endChatConfirmationMessageText: {
          es: '¿De verdad quieres terminar el chat actual? Si sigues necesitando ayuda después de terminar este chat, puedes iniciar uno nuevo.',
          en: 'Do you really want to end current chat? If you still need help after finishing this chat, you can start a new one.',
          pt: 'Você realmente deseja encerrar o bate-papo atual? Se ainda precisar de ajuda depois de terminar este bate-papo, você pode iniciar um novo.'
        },
        endChatMessageText: {
          es: 'El usuario finalizó el chat',
          en: 'The user ends the chat',
          pt: 'O usuário encerra o bate-papo'
        }
      },
      endChatMessage: {
        es: 'Mensaje de notificación interna',
        en: 'Internal notification message',
        pt: 'Mensagem de notificação interna'
      },
      actionTitle: {
        es: 'Acciones',
        en: 'Actions',
        pt: 'Ações'
      },
      footer: {
        es: 'Footer',
        en: 'Footer',
        pt: 'Footer'
      },
      chatLink: {
        show: {
          es: 'Mostrar footer',
          en: 'Show footer',
          pt: 'Mostrar footer'
        },
        description: {
          es: 'Se mostrará abajo de la caja de texto el mensaje: ',
          en: 'The message will be displayed below the text box: ',
          pt: 'A mensagem será exibida abaixo da caixa de texto: '
        }
      },
      advancedSettings: {
        es: 'Ajustes avanzados',
        en: 'Advanced settings',
        pt: 'Configurações avançadas'
      },
      versionScriptsTitle: {
        es: 'Versión y scripts',
        en: 'Version & Scripts',
        pt: 'Versão e scripts'
      },
      chatWindowTitle: {
        es: 'Ventana de chat',
        en: 'Chat window',
        pt: 'Chat window'
      },
      bubbleSize: {
        es: 'Tamaño de la burbuja',
        en: 'Size',
        pt: 'Tamanho da bolha'
      },
      titleError: {
        es: 'Atención!',
        en: 'Warning!',
        pt: 'Aviso!'
      },
      acceptError: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      openError: {
        es:
          'Este widget aún no ha sido habilitado. Para habilitarlo por la primera vez, por favor contáctenos en',
        en:
          'This widget has not been enabled yet. To enable it for the first time, please contact us at ',
        pt:
          'Este widget ainda não foi ativado. Para habilitá-lo pela primeira vez, entre em contato conosco em'
      },
      resetUserLogo: {
        es: 'Restaurar logo por defecto',
        en: 'Restore default logo',
        pt: 'Restaurar o logotipo padrão'
      },
      eraseBg: {
        es: 'Borrar imagen de fondo',
        en: 'Erase background image',
        pt: 'Apagar a imagem de fundo'
      },
      background: {
        es: 'Imagen de fondo',
        en: 'Background image',
        pt: 'Imagem de fundo'
      },
      disableAvatarUser: {
        es: 'Ocultar Avatar de Usuario',
        en: 'Disable Avatar User',
        pt: 'Ocultar Avatar do Usuário'
      },
      enableBold: {
        es: 'Texto de botones en negrita',
        en: 'Bold buttons text',
        pt: 'Texto dos botões em negrito'
      },
      placeholder: {
        es: 'Input Placeholder',
        en: 'Input Placeholder',
        pt: 'Input Placeholder'
      },
      dateplaceholder: {
        es: 'DatePicker Placeholder',
        en: 'DatePicker Placeholder',
        pt: 'DatePicker Placeholder'
      },
      noLocationPermissionMessage: {
        es: 'Mensaje para obtener ubicación manualmente.',
        en: 'Message to get location manually.',
        pt: 'Mensagem para obter a localização manualmente.'
      },
      defaultConfig: {
        restore: {
          es: 'Restaurar configuración por defecto',
          en: 'Restore default settings',
          pt: 'Restaurar as configurações padrão'
        },
        textRestore: {
          es:
            '¿Está seguro que desea restablecer a la última configuración por defecto guardada?',
          en: 'Are you sure you want to restore default settings?',
          pt: 'Tem certeza que quer restaurar as configurações padrão?'
        },
        confirmation: {
          es: 'Confirmación',
          en: 'Confirmation',
          pt: 'Confirmação'
        },
        text: {
          es: '¿Deseas establecer esta configuración como default?',
          en: 'Are you sure you want to set these settings as default?',
          pt: 'Tem certeza que quer definir essas configurações como padrão?'
        },
        textConfirm: {
          es: 'Confirmar cambios',
          en: 'Confirm changes',
          pt: 'Confirme as mudanças'
        }
      },
      botMessage: {
        text: {
          es: 'Color Texto Bot',
          en: 'Bot Text Color',
          pt: 'Cor do Texto Bot'
        },
        bg: {
          es: 'Color Fondo Bot',
          en: 'Bot Background color',
          pt: 'Cor de fundo do bot'
        },
        link: {
          es: 'Color Enlaces Bot',
          en: 'Bot Links color',
          pt: 'Bot Links cor'
        }
      },
      buttons: {
        text: {
          es: 'Color Texto Botones',
          en: 'Buttons Text Color',
          pt: 'Botões Cor do texto'
        },
        bg: {
          es: 'Color Fondo botones',
          en: 'Buttons Background color',
          pt: 'Botões Cor de fundo'
        },
        border: {
          es: 'Color Borde Botones',
          en: 'Buttons border color',
          pt: 'Botões de cor borda'
        }
      },
      reaction: {
        es: 'Habilitar reacción de validación',
        en: 'Enable reaction from validation',
        pt: 'Habilitar a reação da validação'
      },
      bubble: {
        text: {
          es: 'Texto Burbuja',
          en: 'Bubble Text',
          pt: 'Texto de bolha'
        },
        image: {
          es: 'Imagen Burbuja',
          en: 'Bubble Image',
          pt: 'Imagem da bolha'
        },
        color: {
          es: 'Color Burbuja',
          en: 'Bubble Color',
          pt: 'Cor da bolha'
        }
      },
      header: {
        color: {
          text: {
            es: 'Color Texto Header',
            en: 'Header Text Color',
            pt: 'Cor do texto do cabeçalho'
          },
          bg: {
            es: 'Color fondo Header',
            en: 'Header Background color',
            pt: 'Cor do fundo do cabeçalho'
          }
        },
        title: {
          es: 'Título',
          en: 'Title',
          pt: 'Título'
        },
        subtitle: {
          es: 'Subtítulo',
          en: 'Subtitle',
          pt: 'Legenda'
        },
        image: {
          es: 'Imagen',
          en: 'Image',
          pt: 'Imagem'
        }
      },
      domain: {
        es: 'Dominio',
        en: 'Domain',
        pt: 'Domínio'
      },
      title: {
        es: 'Widget',
        en: 'Widget',
        pt: 'Widget'
      },
      history: {
        es: 'Histórico',
        en: 'History',
        pt: 'Histórico'
      },
      historyReset: {
        enable: {
          es: 'Reajuste histórico (horas)',
          en: 'History reset (hours)',
          pt: 'Reposição do histórico (horas)'
        },
        resetEvery: {
          es: 'Reiniciar cada (horas)',
          en: 'Reset every (hours)',
          pt: 'Reinicialização a cada (horas)'
        }
      },
      hasSuggestions: {
        es: 'Mostar en sugerencias',
        en: 'Show in suggestions',
        pt: 'Habilitar em sugestões'
      },
      isActive: {
        es: 'Activo',
        en: 'Active',
        pt: 'Ativo'
      },
      suggestionConfidence: {
        es: 'Confianza de la sugerencia',
        en: 'Suggestion confidence',
        pt: 'Confiança da sugestão'
      },
      suggestionInputWait: {
        es: 'Tiempo máximo de espera',
        en: 'Maximum waiting time',
        pt: 'Tempo máximo de espera'
      },
      suggestionInputCharactersMin: {
        es: 'Caracteres mínimos de entrada',
        en: 'Minimum input characters',
        pt: 'Caracteres de entrada mínima'
      },
      hasFeedback: {
        es: 'Activar la retroalimentación',
        en: 'Enable feedback',
        pt: 'Habilitar o feedback'
      },
      isProactive: {
        es: 'Proactivo',
        en: 'Proactive',
        pt: 'Proativo'
      },
      reload: {
        es: 'Reload',
        en: 'Reload',
        pt: 'Recarregar'
      },
      text: {
        es: 'Texto',
        en: 'Text',
        pt: 'Texto de apresentação'
      },
      onlyImage: {
        es: 'Selecciona una imagen',
        en: 'Select an image',
        pt: 'Selecione uma imagem'
      },
      image: {
        es: 'Imagen',
        en: 'Image',
        pt: 'Imagem'
      },
      dataState: {
        es: 'Mensaje de inicio (DataState)',
        en: 'Start message (DataState)',
        pt: 'Início da mensagem (DataState)'
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'El widget fue actualizada con éxito',
          en: 'The widget was updated successfully.',
          pt: 'O widget foi atualizado com sucesso.'
        }
      },
      startMinimized: {
        es: 'Comenzar minimizado',
        en: 'Start minimized',
        pt: 'Iniciar minimizado'
      },
      enableAudioMessages: {
        es: 'Habilitar mensajes de audio',
        en: 'Enable audio messages',
        pt: 'Habilitar mensagens de áudio'
      },
      hasEndChat: {
        es: 'Opción para finalizar el chat',
        en: 'Option to end chat',
        pt: 'Opção para encerrar o bate-papo'
      },
      hasExternalNPS: {
        es: 'Tiene external NPS',
        en: 'Has external NPS',
        pt: 'Tem NPS externo'
      },
      disableEmojis: {
        es: 'Deshabilitar emojis',
        en: 'Disable emojis',
        pt: 'Desativar emojis'
      },
      enableFiles: {
        es: 'Enviar mensajes con archivos',
        en: 'Send file messages',
        pt: 'Enviar mensagens com arquivos'
      },
      mobileVersion: {
        es: 'Link para versión móvil',
        en: 'Link for Mobile version',
        pt: 'Link para versão mobile'
      },
      color: {
        es: 'Color de fondo',
        en: 'Background Color',
        pt: 'Cor da fundo'
      },
      textColorPresentation: {
        es: 'Color del texto',
        en: 'Text color',
        pt: 'Cor do texto'
      },
      logoBot: {
        es: 'Avatar para el bot',
        en: 'Avatar bot for chat',
        pt: 'Avatar para o bot'
      },
      logoUser: {
        es: 'Avatar para el usuario',
        en: 'Avatar user for chat',
        pt: 'Avatar para o usuário'
      },
      chatColor: {
        es: 'Color de Chat',
        en: 'Chat Color',
        pt: 'Cor do Chat'
      },
      chatFont: {
        es: 'Fuente del Chat',
        en: 'Chat Font Family',
        pt: 'Fonte do Chat'
      },
      isNewChatFont: {
        es: 'Utilice su propio tipo de letra',
        en: 'Use your own font',
        pt: 'Use sua própria fonte'
      },
      newFontFamily: {
        es: 'Nueva familia de fuentes',
        en: 'New font-family',
        pt: 'Nova família de fontes'
      },
      newFontFamilyPlaceholder: {
        es: 'Escriba el nombre de la familia tipográfica...',
        en: 'Write the font-family name...',
        pt: 'Escreva o nome da família de fontes...'
      },
      newFontFamilyTooltip: {
        es: "Escribe en esta entrada el nombre de la font-family. <br/> Luego, en la sección 'Configuración avanzada', añádelo en el css. <br/> Por ejemplo: <br/> @font-face { <br/>  font-family: 'font_name'; <br/>  src: url('https://...') format('woff2'); <br/> }",
        en: "Write in this input the font-family name. <br/> Then in the 'Advanced settings' section, add it in the css. <br/> For example: <br/> @font-face { <br/>  font-family: 'font_name'; <br/>  src: url('https://...') format('woff2'); <br/> }",
        pt: "Escreva nesta entrada o nome da fonte-família. <br/> Em seguida, na seção 'Configurações avançadas', adicione-a no css. <br/> Por exemplo: <br/> @font-face { <br/>  font-family: 'font_name'; <br/>  src: url('https://...') format('woff2'); <br/> }"
      },
      textColor: {
        es: 'Color Texto',
        en: 'Text Color',
        pt: 'Cor do texto'
      },
      welcomeMessage: {
        es: 'Saludo Inicial',
        en: 'Welcome Message',
        pt: 'Mensagem de boas-vindas'
      },
      display: {
        es: 'Presentación',
        en: 'Presentation',
        pt: 'Apresentação'
      },
      chat: {
        es: 'Chat',
        en: 'Chat',
        pt: 'Conversa'
      },
      error: {
        text: {
          empty: {
            es: 'Texto de presentación no puede estar vacío',
            en: 'Presentation text cannot be empty',
            pt: 'Texto de apresentação não pode estar vazio'
          }
        },
        welcomeMessage: {
          empty: {
            es: 'Mensaje de Bienvenida no puede estar vacío',
            en: 'Welcome Message cannot be empty',
            pt: 'Mensagem de boas-vindas não pode estar vazia'
          }
        },
        domain: {
          empty: {
            es: 'Dominio no puede estar vacío',
            en: 'Domain cannot be empty',
            pt: 'O domínio não pode estar vazio'
          }
        },
        image: {
          empty: {
            es: 'La imagen no puede estar vacío',
            en: 'Image cannot be empty',
            pt: 'A imagem não pode estar vazia'
          }
        }
      }
    },
    title: {
      es: 'Nuevo Bot',
      en: 'New bot',
      pt: 'Novo Bot'
    },
    deleteBotPrompt: {
      title: {
        es: 'Borrar bot',
        en: 'Delete bot',
        pt: 'Apagar bot'
      },
      message: {
        es:
          '¿Estás seguro que quieres borrar el bot? Ten en cuenta que esta acción es permanente.',
        en:
          'Are you sure you want to delete the bot? Keep in mind that this action is permanent.',
        pt:
          'Tem certeza que quer apagar o bot? Observe que esta ação é permanente.'
      }
    },
    basicSettings: {
      title: {
        es: 'Configuración básica',
        en: 'Basic settings',
        pt: 'Configurações básicas'
      }
    },
    securitySettings: {
      title: {
        es: 'Configuración de seguridad del JWT',
        en: 'JWT Security settings',
        pt: 'Configurações de segurança JWT'
      },
      description: {
        es: 'Establezca una clave secreta JWT (JSON Web Token) para garantizar la seguridad de su autenticación o transmisión de datos basada en JWT.',
        en: 'Set a JWT (JSON Web Token) secret key to ensure the security of your JWT-based authentication or data transmission.',
        pt: 'Definir uma chave secreta JWT (JSON Web Token) para garantir a segurança da autenticação baseada em JWT ou da transmissão de dados.'
      },
      jwtSecretKey: {
        es: 'Jwt Secret Key',
        en: 'Jwt Secret Key',
        pt: 'Jwt Secret Key'
      },
      active: {
        es: 'Activar token',
        en: 'Activate token',
        pt: 'Ativar token'
      },
      invalidTokenMessage: {
        es: 'Mensaje de token inválido para el usuario',
        en: 'Token failure message to the user',
        pt: 'Mensagem de falha do token para o usuário'
      },
      invalidTokenMessagePlaceholder: {
        es: 'Escriba un mensaje para guiar al usuario sobre las acciones necesarias cuando la verificación del token haya fallado. Por ejemplo Para continuar chateando, actualice sesión.',
        en: 'Write a message to guide the user on the necessary actions when the token verification has failed. e.g: To continue chatting, kindly refresh your session.',
        pt: 'Escreva uma mensagem para orientar o utilizador sobre as acções necessárias quando a verificação do token falhar: Para continuar a conversar, actualize a sua sessão.'
      },
      error: {
        jwtSecretKey: {
          empty: {
            es: 'Mínimo de 8 caracteres (puede incluir letras mayúsculas y minúsculas, números y caracteres especiales)',
            en: 'Minimum of 8 characters (can include upper and lower case letters, numbers, and special characters)',
            pt: 'Mínimo de 8 caracteres (pode incluir letras maiúsculas e minúsculas, números e caracteres especiais)'
          },
        },
        invalidTokenMessage: {
          empty: {
            es: 'Por favor ingrese un mensaje para un token inválido.',
            en: 'Please enter a message for a invalid token.',
            pt: 'Insira uma mensagem para um token inválido.'
          },
        }
      }
    },
    generalSettings: {
      title: {
        es: 'Configuración general',
        en: 'General settings',
        pt: 'Configurações gerais'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      nameValidation: {
        es: 'Por favor ingrese un nombre para su bot',
        en: 'Please enter a name for your bot',
        pt: 'Por favor, digite um nome para o seu bot'
      },
      description: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição'
      },
      descriptionPlaceholder: {
        es: 'Ingrese la descripción de su bot',
        en: 'Enter your bot description',
        pt: 'Digite a descrição de seu bot'
      },
      channels: {
        es: 'Canales:',
        en: 'Channels:',
        pt: 'Canais:'
      },
      error: {
        name: {
          empty: {
            es: 'Por favor ingrese un nombre válido para su bot.',
            en: 'Please enter a valid bot name.',
            pt: 'Por favor, digite um nome de bot válido.'
          },
          invalid: {
            es: 'Por favor no utilice caracteres especiales.',
            en: 'Please do not use special characters.',
            pt: 'Por favor, não use caracteres especiais.'
          }
        },
        channel: {
          es: 'Por favor seleccione un canal',
          en: 'Please select a channel.',
          pt: 'Por favor, selecione um canal.'
        },
        description: {
          empty: {
            es: 'Por favor ingrese una descripción.',
            en: 'Please enter a description.',
            pt: 'Por favor, digite uma descrição.'
          },
          invalid: {
            es: 'Por favor no utilice caracteres especiales.',
            en: 'Please do not use special characters.',
            pt: 'Por favor, não use caracteres especiais.'
          }
        },
        notDialogDetected: {
          empty: {
            es: 'Por favor ingrese la URL del webhook',
            en: 'Please enter the URL of the webhook',
            pt: 'Por favor, digite uma URL do webhook',
          },
        },
      },
      form: {
        noIntentMessage: {
          es:
            'Lo siento, no entendí. ¿Podría reformular la pregunta, por favor?',
          en:
            'Sorry I did not understand. Could you rephrase the question, please?',
          pt:
            'Desculpe, não entendi. Você poderia reformular a pergunta, por favor?'
        },
        connectionErrorMessage: {
          es:
            'Lo siento, pero he perdido momentáneamente la comunicación. ¿Podría por favor escribir nuevamente?',
          en:
            'I am sorry but I temporarily lost communication. Would you please repeat it for me?',
          pt:
            'Desculpe, mas perdi temporariamente a comunicação. Você poderia escrever novamente?'
        },
        inactivityMessage: {
          es: '',
          en: '',
          pt: ''
        },
        noIntentMessage2: {
          es:
            'No estoy logrando entender; por favor, espera en línea y un agente te asistirá',
          en:
            'I am not understanding you, I leave you in contact with an agent so that he can help you better.',
          pt:
            'Não estou conseguindo entender, deixo você em contato com um agente para que ele possa te ajudar melhor.'
        },
        disambiguationMessage: {
          es:
            'Lo lamento, no entendí tu pregunta. Tal vez quisiste preguntar por:',
          en:
            "I'm sorry, I didn't understand your question. Maybe you wanted to ask about:",
          pt:
            'Desculpe, não entendi sua pergunta. Talvez você quisesse perguntar sobre:'
        },
        anythingElseMessage: {
          es: 'Ninguna de las anteriores',
          en: 'None of the above',
          pt: 'Nenhum dos acima'
        },
        anythingElseResponseMessage: {
          es: 'Entiendo, puedo ayudarte con algo más?',
          en: 'I understand, can I help you with something else?',
          pt: 'Entendo, posso ajudá-lo com outra coisa?'
        }
      }
    },
    empathicEngine: {
      title: {
        es: 'Motor de empatía',
        en: 'Empathic engine',
        pt: 'Motor de empatia'
      }
    },
    priorities: {
      title: {
        es: 'Prioridades',
        en: 'Priorities',
        pt: 'Prioridades'
      },
      tableHeaders: {
        priority: {
          es: 'Prioridad',
          en: 'Priority',
          pt: 'Prioridade'
        },
        tags: {
          es: 'Etiquetas',
          en: 'Tags',
          pt: 'Etiquetas'
        },
        intents: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        updated: {
          es: 'Actualizado',
          en: 'Updated',
          pt: 'Atualizado'
        }
      },
      description: {
        es: `Define prioridades para los chats pendientes de atención. Las prioridades pueden ser asignadas tanto a tags como a deteción de intenciones.
        La mayor prioridad posible es 1, la menor prioridad posible es 1000.`,
        en: `Define priorities for pending chats. Priorities can be assigned to both tags and intent detection.
        The highest possible priority is 1, the lowest possible priority is 1000.`,
        pt: `Defina prioridades para bate-papos pendentes. As prioridades podem ser atribuídas a tags e detecção de intent.
        A prioridade mais alta possível é 1, a prioridade mais baixa possível é 1000.`
      },
      disclaimer: {
        es:
          'Para configurar prioridades, es necesario guardar el chatbot anteriormente.',
        en:
          'To set up priorities, it is necessary to save the chatbot previously.',
        pt: 'Para configurar prioridades, é necessário salvar o chatbot antes.'
      },
      deleteConfirmation: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        content: {
          es:
            'Estas por borrar una prioridad, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase a priority, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma prioridade, esta ação é irreversível. Tem certeza?'
        }
      },
      prompt: {
        accept: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        },
        priorityValueLabel: {
          es: 'Prioridad (1 a 1000)',
          en: 'Priority (1 to 1000)',
          pt: 'Prioridade (1 a 1000)'
        },
        intentsLabel: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        intentsPlaceholder: {
          es:
            'Escribe el nombre de una intención y presiona Enter para agregarla',
          en: 'Write the intent name and press Enter to add it',
          pt: 'Digite o nome de uma intenção e pressione Enter para adicioná-la'
        },
        tagsLabel: {
          es: 'Etiquetas',
          en: 'Tags',
          pt: 'Etiquetas'
        },
        tagsPlaceholder: {
          es: 'Escribe una etiqueta y presiona Enter para agregarla',
          en: 'Write a tag and press Enter to add it',
          pt: 'Digite uma etiqueta e pressione Enter para adicioná-la'
        },
        preview: {
          es: 'Previsualizar',
          en: 'Preview',
          pt: 'Visualização'
        },
      },
      addPriority: {
        button: {
          es: 'Crear Prioridad',
          en: 'Create Priority',
          pt: 'Criar Prioridade'
        },
        prompt: {
          title: {
            es: 'Crear Prioridad',
            en: 'Create Priority',
            pt: 'Criar Prioridade'
          }
        }
      },
      editPriority: {
        prompt: {
          title: {
            es: 'Editar Prioridad',
            en: 'Edit Priority',
            pt: 'Editar Prioridade'
          }
        }
      },
      genericError: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'It seems that an error occurred.',
          pt: 'Parece que ocorreu um erro.'
        }
      },
      genericSuccess: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'Cambios guardados con éxito',
          en: 'Changes saved successfully',
          pt: 'Alterações salvas com sucesso'
        }
      },
      invalidPriorityValue: {
        title: {
          es: 'Prioridad inválida',
          en: 'Invalid priority',
          pt: 'Prioridade inválida'
        },
        text: {
          es: 'La prioridad debe ser un número entero entre 1 y 1000',
          en: 'The priority must be an integer between 1 and 1000',
          pt: 'A prioridade deve ser um número inteiro entre 1 e 1000'
        }
      }
    },
    manageUsers: {
      title: {
        es: 'Administrar usuarios',
        en: 'Manage users',
        pt: 'Gerenciar usuários'
      },
      button: {
        es: 'Seleccionar usuarios',
        en: 'Select users',
        pt: 'Selecione usuários'
      },
      table: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        email: {
          es: 'Correo electrónico',
          en: 'Email',
          pt: 'E-mail'
        },
        username: {
          es: 'Nombre de Usuario',
          en: 'Username',
          pt: 'Nome de usuário'
        },
        password: {
          es: 'Contraseña',
          en: 'Password',
          pt: 'Senha'
        },
        role: {
          es: 'Roles',
          en: 'Roles',
          pt: 'Roles'
        },
        channels: {
          es: 'Canales habilitados',
          en: 'Enabled channels',
          pt: 'Canais habilitados'
        },
        download: {
          es: 'Descargar',
          en: 'Download',
          pt: 'Baixe',
        },
        active: {
          es: 'Activo',
          en: 'Active',
          pt: 'Ativo',
        },
        blocked: {
          es: 'Bloqueado',
          en: 'Blocked',
          pt: 'Bloqueado',
        },
        status: {
          es: 'Estado',
          en: 'Status',
          pt: 'Status'
        },
        editOptions: {
          edit: {
            es: 'Editar',
            en: 'Edit',
            pt: 'Editar',
          },
          unblock: {
            es: 'Desbloquear',
            en: 'Unblock',
            pt: 'Desbloquear',
          },
          block: {
            es: 'Bloquear',
            en: 'Block',
            pt: 'Bloquear',
          },
          remove: {
            es: 'Eliminar',
            en: 'Remove',
            pt: 'Remover',
          },
        }
      },
      dialog: {
        title: {
          es: 'Crear un usuario',
          en: 'Add user',
          pt: 'Adicionar usuário'
        },
        description: {
          es: 'Complete el formulario para crear un nuevo usuario',
          en: 'Complete the form to create a new user.',
          pt: 'Complete o formulário para criar um novo usuário.'
        },
        addBtn: {
          es: 'Crear usuario',
          en: 'Create user',
          pt: 'Criar usuário'
        },
        role: {
          admin: {
            es: 'Administrador',
            en: 'Admin',
            pt: 'Administração'
          },
          agent: {
            es: 'Agente',
            en: 'Agent',
            pt: 'Agente'
          }
        },
        errorMessage: {
          es: 'Por favor completa todos los campos para continuar',
          en: 'Please complete all the fields in order to continue',
          pt: 'Por favor, complete todos os campos para continuar'
        },
        errorsAPI: {
          existingEmail: {
            es: 'Ya existe un usuario con ese correo electrónico.',
            en: 'Email already in use.',
            pt: 'E-mail já está em uso.'
          }
        }
      },
      dialogSelectUsers: {
        title: {
          es: 'Usuarios',
          en: 'users',
          pt: 'Usuários'
        },
        alert: {
          es: 'Por favor selecciona uno o mas usuarios',
          en: 'Please select one or more users',
          pt: 'Por favor, selecione um ou mais usuários'
        },
        selectUsers: {
          es: 'Seleccionar usuarios',
          en: 'Select users',
          pt: 'Selecione usuários'
        }
      }
    },
    manageQueues: {
      empty: {
        title: {
          es: 'Todavía no hay colas de atención',
          en: 'There are no attention queues yet',
          pt: 'Não há filas de atenção ainda'
        },
      },
      title: {
        es: 'Administrar Colas de Atención',
        en: 'Manage Attention Queues',
        pt: 'Gerenciar Fila de Atenção'
      },
      button: {
        es: 'Seleccionar colas',
        en: 'Select queues',
        pt: 'Selecione filas'
      },
      table: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        description: {
          es: 'Descripción',
          en: 'Description',
          pt: 'Descrição'
        },
      },
      dialogSelectQueues: {
        title: {
          es: 'Colas de Atención',
          en: 'Attention Queues',
          pt: 'Filas de Atenção'
        },
        alert: {
          es: 'Por favor selecciona una o mas colas',
          en: 'Please select one or more queues',
          pt: 'Por favor, selecione um ou mais filas'
        },
        selectQueues: {
          es: 'Seleccionar Colas de Atención',
          en: 'Select Attention Queues',
          pt: 'Filas de Atenção '
        }
      }
    },
    fileAttachments: {
      title: {
        es: 'Archivos adjuntos',
        en: 'File Attachments',
        pt: 'Anexos de arquivos'
      },
      showPreviewAndDownload: {
        es: 'Mostrar vista previa y descarga en el historial de chat.',
        en: 'Show preview and download in chat history.',
        pt: 'Mostrar visualização e download no histórico do bate-papo.'
      },
      systemRetention: {
        title: {
          es: 'Retención del sistema',
          en: 'System Retention',
          pt: 'Retenção do sistema'
        },
        options: {
          option1: {
            es: 'Mantener permanentemente',
            en: 'Keep permanently',
            pt: 'Manter permanentemente'
          },
          option2: {
            es: 'Horas',
            en: 'Hours',
            pt: 'Horas'
          }
        },
        infoPart1: {
          es: 'El archivo permanecerá en el sistema durante',
          en: 'The file will remain in the system for',
          pt: 'O arquivo permanecerá no sistema por'
        },
        infoPart2: {
          es: 'horas',
          en: 'hours',
          pt: 'horas'
        }
      }
    },
    engineSetting: {
      title: {
        es: 'Configuración del motor de procesamiento de lenguaje natural',
        en: 'Natural Language Processing Engine Settings',
        pt: 'Configuração do motor de processamento de linguagem natural'
      },
      platform: {
        es: 'Plataforma',
        en: 'Platform',
        pt: 'Plataforma'
      },
      deprecated: {
        es: 'Deprecado',
        en: 'Deprecated',
        pt: 'Depreciada'
      },
      platformError: {
        es: 'Por favor seleccione una plataforma.',
        en: 'Please select a platform',
        pt: 'Por favor, selecione uma plataforma'
      },
      culture: {
        es: 'Idioma',
        en: 'Language',
        pt: 'Idioma'
      },
      cultureOptions: {
        spanish: {
          es: 'Español',
          en: 'Spanish',
          pt: 'Espanhol'
        },
        english: {
          es: 'Inglés',
          en: 'English',
          pt: 'Inglês'
        },
        portuguese: {
          es: 'Portugués',
          en: 'Portuguese',
          pt: 'Português'
        }
      },
      cultureError: {
        es: 'Por favor seleccione un idioma.',
        en: 'Please select a language.',
        pt: 'Por favor, selecione um idioma.'
      },
      intentConfidence: {
        es: 'Confianza de la intención',
        en: 'Intent confidence',
        pt: 'Confiança da intenção'
      },
      intentConfidenceOptions: {
        high: {
          es: 'Alto',
          en: 'High',
          pt: 'Alto'
        },
        medium: {
          es: 'Medio',
          en: 'Medium',
          pt: 'Médio'
        },
        low: {
          es: 'Bajo',
          en: 'Low',
          pt: 'Baixo'
        }
      },
      intentConfidenceError: {
        es: 'Por favor seleccione la confianza de la intención',
        en: 'Please select the intent confidence.',
        pt: 'Por favor, selecione a confiança da intenção.'
      },
      noIntentMessage: {
        es: 'Acción cuando se desconoce la intención',
        en: 'No intent action',
        pt: 'Ação quando a intenção é desconhecida'
      },
      connectionErrorMessage: {
        es: 'Mensaje cuando ocurre un error de conexión con el NLU',
        en: 'NLU connection error message',
        pt: 'Mensagem de erro de conexão NLU'
      },
      timeToTimeoutAction: {
        es: 'Tiempo máximo de espera a agente (minutos)',
        en: 'No intent action (minutes)',
        pt: 'Tempo máximo de espera para agente (minutos)'
      },
      timeToTimeoutActionTooltip: {
        es: 'Tiempo máximo de espera a agente tooltip',
        en: 'No intent action',
        pt: 'Tempo máximo de espera a agente tooltip'
      },
      timeoutActionExecute: {
        es: 'Configurar y ejecutar acción',
        en: 'Set and execute action',
        pt: 'Definir e executar ação'
      },
      timeoutAction: {
        es: 'Enviar pregunta a Usuarios en espera en Pedidos de Atención',
        en: 'Send question to Users at Pending Chats',
        pt: 'Envie uma pergunta para Usuários em Chats Pendentes'
      },
      notDialogDetected: {
        text: {
          es: 'Diálogo no detectado',
          en: 'Dialog not detected',
          pt: 'Diálogo não detectada'
        },
        tooltip: {
          es:
            'Permite configurar un webhook para re-dirigir el mensaje del usuario, cuando el bot no entienda la intención, y obtener una respuesta externa.',
          en:
            'It allows configuring a webhook to redirect the user message, when the bot does not understand the intention, and obtain an external response.',
          pt:
            'Permite configurar um webhook para re-dirigir a mensagem do usuário, cuando o bot sem entienda a intenção, e obtener uma resposta externa.'

        }
      },
      disambiguation: {
        text: {
          es: 'Desambiguación',
          en: 'Disambiguation',
          pt: 'Desambiguação'
        },
        tooltip: {
          es:
            'Permite configurar si el Bot puede desambiguar entre opciones cercanas para el texto ingresado por el usuario.',
          en:
            'It allows configuring if the Bot can disambiguate between nearby options for the text entered by the user.',
          pt:
            'Permite configurar se o Bot pode desambiguar entre as opções próximas para o texto digitado pelo usuário.'
        },
        message: {
          es: 'Mensaje de desambiguación',
          en: 'Disambiguation message',
          pt: 'Mensagem de desambiguação'
        },
        maxIntents: {
          es: 'Max. intenciones sugeridas',
          en: 'Intents max suggestion',
          pt: 'Máx. intenções sugeridas'
        },
        maxIntentsError: {
          es: 'Ingrese un valor entre 1 y 5',
          en: 'Insert a value between 1 and 5',
          pt: 'Insira um valor entre 1 e 5'
        },
        anythingElseMessage: {
          title: {
            es: 'Configurar mensaje para ninguna opción',
            en: 'Set message for no option',
            pt: 'Definir mensagem para nenhuma opção'
          },
          message: {
            es: 'Mensaje ninguna opción',
            en: 'Message no option',
            pt: 'Mensagem sem opção'
          }
        }
      },
      timeoutActionPopup: {
        es: 'Preguntar a Usuarios en espera',
        en: 'Send question to Users at Pending Chats',
        pt: 'Envie uma pergunta para usuários em Chats Pendentes'
      },
      timeoutActionName: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      timeoutActionTooltip: {
        es:
          'Permite configurar el envío de una pregunta (así como las distintas acciones según la respuesta del usuario) a aquellos usuarios que han sido derivados para asistencia humana y están en espera en Pedidos de Atención',
        en:
          'This feature allows the set up of a question (as well as its resulting actions) to those users that have been transferred to human attention, and are currently waiting at Pending Chats',
        pt:
          'Esta funcionalidade permite a configuração de uma pergunta ( bem como suas ações resultantes) para os usuários que foram transferidos para a atendimento humana, e estão atualmente esperando em Chats Pendentes'
      },
      noIntentMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      connectionErrorMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      intents: {
        es: 'Intención para derivar a humano',
        en: 'Intention to derive a human',
        pt: 'Intenção de derivar a humano'
      },
      intentions: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        }
      },
      showSuggestions: {
        es: 'Mostrar sugerencias de respuesta para agentes',
        en: 'Show response suggestions for agents',
        pt: 'Mostrar sugestões de respostas para agentes'
      },
      showSuggestionsTooltip: {
        es: 'Muestra respuestas sugeridas a los agentes. El entrenamiento del bot se utiliza para procesar la entrada del usuario y sugerir una respuesta apropiada dentro de la pantalla de solicitudes de atención, mientras el agente habla con el usuario.',
        en: 'Shows suggested responses to the agents. Bot training is used to process user input and suggest an appropriate response within attention requests page in Konecta\'s contact center, while the agent is talking to the user.',
        pt: 'Mostra respostas sugeridas aos agentes. O treinamento do bot é usado para processar a entrada do usuário e sugerir uma resposta apropriada na tela de solicitações de atenção, enquanto o agente conversa com o usuário.'
      },
      inactivityTime: {
        es: 'Tiempo máximo de inactividad (minutos)',
        en: 'Maximum inactivity time (minutes)',
        pt: 'Tempo máximo de inatividade (minutos)'
      },
      inactivityTimeTooltip: {
        es: 'Introduzca el tiempo de inactividad en minutos. Pasado este tiempo de inactividad, el asistente virtual envía un mensaje preguntando si el usuario sigue allí. Si este campo es 0, el asistente virtual no envía ningún mensaje.',
        en: 'Insert the idle time in minutes. After this time of inactivity, the virtual assistant sends a message asking if the user is still there. If this field is 0, the virtual assistant does not send any messages.',
        pt: 'Insira o tempo ocioso em minutos. Após esse tempo de inatividade, a assistente virtual envia uma mensagem perguntando se o usuário ainda está lá. Se este campo for 0, o assistente virtual não envia nenhuma mensagem.'
      },
      inactivityTimeError: {
        es: 'Por favor ingrese un valor.',
        en: 'Please enter a value.',
        pt: 'Por favor, digite um valor.'
      },
      inactivityMessage: {
        es: 'Mensaje a enviar pasado el tiempo de inactividad',
        en: 'Message to send after inactivity time',
        pt: 'Mensagem para enviar depois do tempo de inatividade'
      },
      notDialogDetectedWebhook: {
        es: 'URL a enviar la consulta del usuario',
        en: 'URL to send the user query',
        pt: 'URL para enviar a consulta do usuário'
      },
      entityExtractor: {
        text: {
          es: 'Extractor de entidades',
          en: 'Entity extractor',
          pt: 'Extrator de entidade'
        },
        tooltip: {
          es:
            'Convierte números de texto en números literales (primero -> 1). Recomendado para canales de voz.',
          en:
            'Converts text numbers into literal numbers (first -> 1). Recommended for voice channels.',
          pt:
            'Converte números de texto em números literais (primeiro -> 1). Recomendado para canais de voz.'
        }
      },
      useAsTemplate: {
        text: {
          es: 'Usar como template',
          en: 'Use as template',
          pt: 'Usar como template'
        },
        tooltip: {
          es:
            'Permite usar el bot como template.',
          en:
            'Allows to use the bot as a template.',
          pt:
            'Permite usar o bot como template.'
        }
      },
      formatTypes: {
        date: {
          es: 'Formato de fecha',
          en: 'Date format',
          pt: 'Formato da data'
        },
        text: {
          es: 'Formato',
          en: 'Formatting',
          pt: 'Formatação'
        },
        number: {
          es: 'Precisión numérica',
          en: 'Number precision',
          pt: 'Precisão numérica'
        }
      },
      inactivityMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      noIntentTimesToTakeOver: {
        es: 'Cantidad de errores antes de derivar a un agente',
        en: 'Number of errors before moving on to an agent',
        pt: 'Número de erros antes de passar a um agente'
      },
      noIntentTimesToTakeOverForAnswersBot: {
        es: 'Cantidad de errores antes de redirigir a una respuesta',
        en: 'Number of errors before redirecting to an answer',
        pt: 'Número de erros antes de redirecionar para uma resposta'
      },
      noIntentTimesToTakeOverTooltipForAnswersBot: {
        es:
          'Redirigirá a la respuesta seleccionada luego de la cantidad de veces que se determine. Ingresar 0 si no debe redirigir en este caso.',
        en:
          'It will redirect to the selected answer after the number of times to be determined. Enter 0 if you should not redirect in this case.',
        pt:
          'O assistente virtual redirecionará para a resposta selecionada após o número de vezes que for determinado. Digite 0 se você não deve redirecionar neste caso.'
      },
      noIntentTimesToTakeOverError: {
        es: 'Por favor ingrese un valor. Ingrese 0 si nunca debe derivar',
        en: 'Please enter a value. Enter 0 if you should never derive',
        pt: 'Por favor, digite um valor. Insira 0 se você nunca deve derivar'
      },
      platformsNoIntentTimesToTakeOver: {
        es: 'Canales que deriva a un agente cuando el bot no entiende',
        en: "Channels that move to an agent when the bot doesn't understand",
        pt: 'Canais que se movem para um agente quando o bot não entende'
      },
      platformsNoIntentTimesToTakeOverForAnswersBot: {
        es: 'Canales que redirigen a una respuesta cuando el bot no entiende',
        en: "Channels that redirect to an answer when the bot doesn't understand",
        pt: 'Canais que redirecionam para uma resposta quando o bot não entende'
      },
      platformsNoIntentTimesToTakeOverTooltipForAnswersBot: {
        es:
          'Selecciona las plataformas dónde se redirigá a una respuesta cuando el bot no entiende luego de la cantidad configurada de veces (arriba)',
        en:
          'Select the platforms which are redirected to an answer when the bot does not understand after the configured number of times (above)',
        pt:
          'Selecione as plataformas que são redirecionadas para uma resposta quando o bot não entender após o número de vezes configurado (acima)'
      },
      noIntentMessage2: {
        es: 'Mensaje al derivar a agente',
        en: 'Message when referring to agent',
        pt: 'Mensagem quando se refere a um agente'
      },
      noIntentRedirectToAnswer: {
        es: 'Redirigir a la respuesta',
        en: 'Redirect to answer',
        pt: 'Redirecionar para resposta'
      },
      noIntentRedirectToAnswerTooltip: {
        es: 'Respuesta a la que se quiere redirigir, luego de que se alcanzó la cantidad de errores previamente definida',
        en: 'Response to which you want to redirect, after the previously defined number of errors has been reached',
        pt: 'Resposta para a qual você deseja redirecionar, após o número de erros previamente definido ser atingido'
      },
    },
    sessionSettings: {
      title: {
        es: 'Expiración de sesión',
        en: 'Session expiration',
        pt: 'Expiração da sessão'
      },
      sessionTimeoutStatus0: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      },
      sessionTimeOutInfo: {
        es: 'Establece un límite de tiempo tras el cual la conversación finalizará debido a la inactividad del usuario.',
        en: 'Set a time limit after which the conversation will end due to user inactivity.',
        pt: 'Defina um limite de tempo após o qual a conversa terminará devido à inatividade do utilizador.'
      },
      sessionTimeoutStatus0Tooltip: {
        es:
          'Para chats en status “Bot”: tiempo en horas que pasará desde que el bot envió el último mensaje, para que se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Bot” status: time in hours that will pass since the bot sent the last message, to be considered the beginning of a new session.',
        pt:
          'Para chats em status “Bot”: tempo em horas que passará desde que o bot enviou a última mensagem, para ser considerado o início de uma nova sessão.'
      },
      sessionTimeoutStatus1: {
        es: 'Pendiente',
        en: 'Pending',
        pt: 'Pendente'
      },
      sessionTimeoutStatus1Tooltip: {
        es:
          'Estando en la cola de pedidos de atención. Es el tiempo que debe pasar desde el último mensaje del usuario y el momento en el que un agente toma el control, para considerar que esta toma de control es una sesión nueva.',
        en:
          'Being in the queue for attention requests. It is the time that must pass from the last message of the user and the moment in which an agent takes control, to consider that this takeover is a new session.',
        pt:
          'Estar na fila de pedidos de atendimento. É o tempo que deve transcorrer da última mensagem do usuário e o momento em que um agente toma o controle, para considerar que essa tomada é uma nova sessão.'
      },
      sessionTimeoutStatus2: {
        es: 'En atención',
        en: 'Active',
        pt: 'Em atendimento'
      },
      sessionTimeoutStatus2Tooltip: {
        es:
          'Para chats en status “En Atención”: tiempo en horas que pasará desde el último mensaje, para que cualquier acción posterior (como continuar respondiendo, o finalizar el chat) se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Active” status: time in hours that will pass from the last message, so that any subsequent action (such as continuing to reply, or ending the chat) is considered the beginning of a new session.',
        pt:
          'Para chats em status “Em atendimento”: tempo em horas que passará da última mensagem, de modo que qualquer ação subsequente (como continuar a responder, ou terminar o chat) é considerado o início de uma nova sessão.'
      },
      sessionTimeoutStatus3: {
        es: 'Liberado',
        en: 'Released',
        pt: 'Liberado'
      },
      sessionTimeoutStatus3Tooltip: {
        es:
          'Para chats en status “Liberado”: tiempo en horas que pasará desde el último mensaje, para que cualquier acción posterior (como continuar respondiendo, o un agente tomando nuevamente el chat) se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Released” status: time in hours that will pass from the last message, so that any subsequent action (such as continuing to reply, or an agent taking the chat again) is considered the beginning of a new session.',
        pt:
          'Para chat em estado “Liberado”: tempo em horas que passarão da última mensagem, de modo que qualquer ação subsequente (como continuar a responder, ou um agente fazendo o chat novamente) é considerado o início de uma nova sessão.'
      }
    },
    intentionTriggerSecondLevel: {
      title: {
        es: 'Configurar intenciones',
        en: 'Set intentions',
        pt: 'Definir intenções'
      },
      table: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        },
        botResponses: {
          es: 'Respuestas del bot',
          en: 'Bot responses',
          pt: 'Respostas do bot'
        },
        derivesToAgent: {
          es: 'Deriva a un agente',
          en: 'Transfers to agent',
          pt: 'Transferências para o agente'
        },
        selectChannels: {
          es: 'Seleccionar canales',
          en: 'Select channels',
          pt: 'Selecione canais'
        }
      },
      form: {
        name: {
          es: 'Nombre de la intención',
          en: 'Intention name',
          pt: 'Nome da intenção'
        },
        examples: {
          es: 'Ejemplo',
          en: 'Example',
          pt: 'Exemplo'
        },
        addExample: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar'
        },
        genExample: {
          es: 'Generar',
          en: 'Generate',
          pt: 'Gerar'
        },
        genExampleError: {
          es: 'Ha ocurrido un error al generar los ejemplos.',
          en: 'An error has ocurred while generating examples.',
          pt: 'Um erro ocorreu ao gerar exemplos.'
        },
        nameError: {
          es: 'Por favor ingrese un nombre válido',
          en: 'Please enter a valid name',
          pt: 'Por favor, digite um nome válido'
        },
        examplesError: {
          es: 'Por favor ingrese un ejemplo válido',
          en: 'Please enter a valid intention example',
          pt: 'Por favor, digite um exemplo de intenção válido'
        },
        emptyIntention: {
          es: 'Por favor ingresa una intención.',
          en: 'Please enter an intention.',
          pt: 'Por favor, insira uma intenção.'
        },
        existingIntent: {
          es: 'La intención que intentas agregar ya existe.',
          en: 'The intent already exists',
          pt: 'A intenção já existe'
        },
        botResponse: {
          error: {
            emptyBotResponse: {
              es: 'Por favor ingrese una respuesta del bot',
              en: 'Please enter a bot response',
              pt: 'Por favor, digite uma resposta de bot'
            }
          }
        },
        derivesToAgent: {
          es: 'Deriva a un agente',
          en: 'Transfers to agent',
          pt: 'Transferência para o agente'
        }
      },
      prompt: {
        title: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        description: {
          es: 'Por favor selecciona una o mas intenciones.',
          en: 'Please select one or more intentions.',
          pt: 'Por favor, selecione uma ou mais intenções.'
        },
        acceptBtn: {
          es: 'Seleccionar',
          en: 'Select',
          pt: 'Selecione'
        },
        error: {
          intent: {
            empty: {
              es: 'Por favor ingresa un nombre para la intención.',
              en: 'Please enter a name for the intention.',
              pt: 'Por favor, digite um nome para a intenção.'
            },
            duplicate: {
              es: 'Ya existe una intención con ese nombre.',
              en: 'There is already an intention with that name.',
              pt: 'Já há uma intenção com esse nome.'
            }
          },
          examples: {
            empty: {
              es: 'Por favor ingresa un ejemplo para la intención.',
              en: 'Please enter an example for the intention.',
              pt: 'Por favor, digite um exemplo para a intenção.'
            },
            duplicate: {
              es: 'Ya existe un ejemplo con el mismo nombre',
              en: 'That example already exists with the same name',
              pt: 'Esse exemplo já existe com o mesmo nome'
            },
            intentionInfo: {
              es: 'en la intención',
              en: 'in intention',
              pt: 'em intenção'
            }
          }
        },
        success: {
          title: {
            es: 'Éxito!',
            en: 'Success!',
            pt: 'Sucesso!'
          },
          text: {
            es: 'La intención fue guardada',
            en: 'The intention was saved',
            pt: 'A intenção foi salva'
          }
        },
        successUpdate: {
          es: 'La intención fue actualizada con éxito',
          en: 'The intention was updated successfully.',
          pt: 'A intenção foi atualizada com sucesso.'
        }
      },
      secondLevelDerivation: {
        message: {
          es: 'Un agente se comunicará a la brevedad.',
          en: 'An agent will contact you shortly.',
          pt: 'Um agente contatará você em breve.'
        },
        description: {
          es:
            'Ingrese el mensaje a mostrar cuando el usuario es derivado a un agente. (Este mensaje será aplicado a todas las intenciones)',
          en:
            'Enter the message to display when the user is referred to an agent. (This message will be used for all intentions)',
          pt:
            'Digite a mensagem para exibir quando o usuário for encaminhado para um agente. (Esta mensagem será usada para todas as intenções)'
        },
        errorMessage: {
          es: 'Por favor ingrese un mensaje',
          en: 'Please enter a message',
          pt: 'Por favor, digite uma mensagem'
        }
      },
      deleteIntentConfirmation: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        content: {
          es:
            'Estas por borrar una intención, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase an intention, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma intenção, esta ação é irreversível. Tem certeza?'
        }
      },
      deleteSuccess: {
        title: {
          es: 'Intención borrada con éxito',
          en: 'Intent deleted successfully',
          pt: 'Intenções excluídas com sucesso'
        }
      }
    },
    timeZone: {
      title: {
        es: 'Zona horaria',
        en: 'Time zone',
        pt: 'Fuso horário'
      }
    },
    botAvailability: {
      title: {
        es: 'Disponibilidad del agente',
        en: 'Agent availability',
        pt: 'Disponibilidade do agente'
      },
      daysAvailability: {
        es: 'Dias disponibles de los agentes:',
        en: 'Days available for agents:',
        pt: 'Dias disponíveis para agentes:'
      },
      selectWeekDays: {
        es: 'Días y horarios de atención',
        en: 'Available days and business hours',
        pt: 'Dias e horas de atendimento disponíveis'
      },
      selectTime: {
        es: 'Horario de atención',
        en: 'Business hours',
        pt: 'Horário de atendimento'
      },
      checkAvailability: {
        es: 'No poner en espera los chats para recibir asistencia fuera del horario de atención.',
        en: 'Chats won´t be queued for assistance outside of business hours.',
        pt: 'Os chats não ficarão em espera para assistência fora do horário de atenção.'
      },
      dayFrom: {
        es: 'De',
        en: 'From',
        pt: 'De'
      },
      weekDaysOptions: {
        0: {
          es: 'Domingo',
          en: 'Sunday',
          pt: 'Domingo'
        },
        1: {
          es: 'Lunes',
          en: 'Monday',
          pt: 'Segunda-feira'
        },
        2: {
          es: 'Martes',
          en: 'Tuesday',
          pt: 'Terça-feira'
        },
        3: {
          es: 'Miércoles',
          en: 'Wednesday',
          pt: 'Quarta-feira'
        },
        4: {
          es: 'Jueves',
          en: 'Thursday',
          pt: 'Quinta-feira'
        },
        5: {
          es: 'Viernes',
          en: 'Friday',
          pt: 'Sexta-feira'
        },
        6: {
          es: 'Sábado',
          en: 'Saturday',
          pt: 'Sábado'
        }
      },
      weekDaysAbbr: {
        monday: {
          es: 'Lun',
          en: 'Mon',
          pt: 'Seg'
        },
        tuesday: {
          es: 'Mar',
          en: 'Tue',
          pt: 'Terç'
        },
        wednesday: {
          es: 'Mié',
          en: 'Wed',
          pt: 'Qua'
        },
        thursday: {
          es: 'Jue',
          en: 'Thu',
          pt: 'Qui'
        },
        friday: {
          es: 'Vie',
          en: 'Fri',
          pt: 'Sex'
        },
        saturday: {
          es: 'Sáb',
          en: 'Sat',
          pt: 'Sáb'
        },
        sunday: {
          es: 'Dom',
          en: 'Sun',
          pt: 'Dom'
        }
      },
      dayTo: {
        es: 'A',
        en: 'To',
        pt: 'Até'
      },
      daysAvailabilityError: {
        noSelection: {
          es:
            'Por favor seleccione los días en los cuales su agente va a operar.',
          en: 'Please select the days on which your agent will operate.',
          pt: 'Por favor, selecione os dias em que seu agente irá operar.'
        },
        invalidTime: {
          es:
            'Por favor seleccione correctamente los horarios en los cuales su agente va a operar.',
          en:
            'Please correctly select the times in which your agente will operate.',
          pt:
            'Por favor, selecione corretamente os horários em que seu agente irá operar.'
        },
        incorrectTime: {
          es: 'Los horarios seleccionados no son válidos.',
          en: 'The timeframe is not valid',
          pt: 'Os horários selecionados não são válidos.'
        },
        messageError: {
          es:
            'Por favor ingrese el mensaje a monstrar cuando el agente no se encuentre disponible',
          en:
            'Please enter the message to show when the agent is not available',
          pt:
            'Por favor, digite a mensage para mostrar quando o agente não estiver disponível'
        },
        existingDate: {
          es: 'La fecha seleccionada ya fue agregada',
          en: 'The selected date has already been added',
          pt: 'A data selecionada já foi adicionada'
        }
      },
      exceptions: {
        es: 'Excepciones',
        en: 'Exceptions',
        pt: 'Exceções'
      },
      datesSelected: {
        es: 'Fechas seleccionadas',
        en: 'Dates selected',
        pt: 'Datas selecionadas'
      },
      addException: {
        es: 'Agregar excepción',
        en: 'Add exception',
        pt: 'Adicionar exceção'
      },
      message: {
        es: 'Mensaje a mostrar fuera del horario de atención',
        en: 'Message to display outside of business hours',
        pt: 'Mensagem para exibir fora do horário de atendimento'
      },
      messageError: {
        es:
          'Por favor ingrese el mensaje que se mostrará cuando su agente no se encuentro disponible.',
        en:
          'Please enter the message that will be displayed when your agent is not available.',
        pt:
          'Por favor, digite a mensagem que será exibida quando seu agente não estiver disponível.'
      },
      dates: {
        es: 'Fechas',
        en: 'Dates',
        pt: 'Datas'
      }
    },
    description: {
      name: {
        es: 'Escribe un nombre para tu bot',
        en: 'Type a name for your bot',
        pt: 'Escreva um nome para o seu bot'
      },
      platform: {
        es: 'Selecciona los canales',
        en: 'Select the channels',
        pt: 'Selecione os canais'
      },
      botDescription: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição'
      }
    },
    startDevelop: {
      es: 'Crear bot',
      en: 'Create bot',
      pt: 'Criar bot'
    },
    notifications: {
      noPlatforms: {
        title: {
          es: 'Falta algo',
          en: 'Something is missing',
          pt: 'Algo está faltando'
        },
        text: {
          es: 'Selecciona por lo menos un canal',
          en: 'You must select at least one channel',
          pt: 'Você deve selecionar pelo menos um canal'
        }
      },
      couldNotSaveWidget: {
        title: {
          es: 'Algo salio mal',
          en: 'Oops something went wrong',
          pt: 'Oops algo deu errado'
        },
        text: {
          es: 'No pudimos guardar el widget',
          en: "We couldn't save your widget",
          pt: 'Não conseguimos salvar seu widget'
        }
      },
      couldNotSaveBot: {
        title: {
          es: 'Algo salio mal',
          en: 'Oops something went wrong',
          pt: 'Oops algo deu errado'
        },
        text: {
          es: 'No pudimos guardar tu bot. Intenta mas tarde',
          en: "We couldn't save your bot. Please try again later",
          pt:
            'Não foi possível salvar seu bot. Por favor, tente de novo mais tarde'
        }
      }
    },
    noIntentMessage: {
      es: 'No entendi lo que me quisiste decir.',
      en: "I didn't understand what you are trying to say",
      pt: 'Eu não entendi o que você está tentando dizer'
    },
    connectionErrorMessage: {
      es: 'Ocurrio un error de conexion con el NLU',
      en: 'NLU error connection',
      pt: 'Ocorreu um erro de conexão com o NLU'
    },
    botChannels: {
      title: {
        es: 'Canales',
        en: 'Channels',
        pt: 'Canais'
      },
      description: {
        es: 'Activar el/los canal/es a utilizar',
        en: 'Activate the channels to be used',
        pt: 'Ativar os canais a serem usados'
      },
      confirm: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
      },
    },
    botWebhooks: {
      title: {
        es: 'Webhooks',
        en: 'Webhooks',
        pt: 'Webhooks'
      },
      description: {
        es: 'Activa enlaces web globales para diferentes eventos del chatbot, recibe y guarda datos en el sistema.',
        en: 'Activates global web links for different chatbot events, receives and saves data in the system.',
        pt: 'Activa ligações web globais para diferentes eventos do chatbot, recebe e guarda dados no sistema.'
      },
      tableHeaders: {
        key: {
          es: 'Evento de Activación',
          en: 'Event Trigger',
          pt: 'Evento de Disparo'
        },
        url: {
          es: 'URL',
          en: 'URL',
          pt: 'URL',
        },
        status: {
          es: 'Estado',
          en: 'Status',
          pt: 'Estado'
        }
      },
      button: {
        es: 'Nuevo Webhook',
        en: 'New Webhook',
        pt: 'Novo Webhook'
      },
      statusNames: {
        status: {
          active: {
            es: 'Activo',
            en: 'Active',
            pt: 'Activo'
          },
          inactive: {
            es: 'Inactivo',
            en: 'Inactive',
            pt: 'Inactivo'
          }
        },
        error: {
          es: 'Error',
          en: 'Error',
          pt: 'Error'
        }
      },
      prompt: {
        types: {
          es: 'Tipo de Webhook',
          en: 'Webhook type',
          pt: 'Tipo Webhook'
        },
        editWebhook: {
          title: {
            es: 'Editar Webhook',
            en: 'Edit Webhook',
            pt: 'Editar Webhook'
          },
        },
        addWebhook: {
          title: {
            es: 'Crear Webhook',
            en: 'Create Webhook',
            pt: 'Criar Webhook'
          },
        },
        readOnlyWebhook: {
          title: {
            es: 'Ver Webhook (solo lectura)',
            en: 'View Webhook (read-only)',
            pt: 'Ver Webhook (somente leitura)'
          }
        },
        accept: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        },
        keysLabel: {
          es: 'Key',
          en: 'Key',
          pt: 'Key'
        },
        keysPlaceHolder: {
          es: 'Seleccionar una llave',
          en: 'Select a key',
          pt: 'Seleccionar uma tecla'
        },
        urlsLabel: {
          es: 'URL',
          en: 'URL',
          pt: 'URL',
        },
        apisLabel: {
          es: 'API',
          en: 'API',
          pt: 'API'
        },
        urlsPlaceHolder: {
          es: 'Escribe o pega una URL',
          en: 'Write or paste an URL',
          pt: 'Escrever ou colar um URL'
        },
        activeSliderLabel: {
          es: 'Activar Webhook',
          en: 'Activate Webhook',
          pt: 'Activar o webhook'
        },
        activeSliderSecretKeyLabel: {
          es: 'Habilitar Secret Key',
          en: 'Enable Secret Key',
          pt: 'Activar Secret Key'
        },
        addUrlLabel: {
          es: 'Añadir URL',
          en: 'Add URL',
          pt: 'Adicionar URL'
        },
        selectApiLabel: {
          es: 'Selecciona API de integración ',
          en: 'Select integration API',
          pt: 'Selecionar API de integração'
        }
      },
      destination: {
        es: 'Destino del Webhook',
        en: 'Webhook Destination',
        pt: 'Destino do Webhook'
      },
      validation: {
        invalidUrlError: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'El campo url ingresado es invalido.',
            en: 'The url field seems to be invalid.',
            pt: 'O campo url parece ser inválido.'
          }
        },
        invalidSecretKey: {
          title: {
            es: 'Oops revisa el campo secret Key!',
            en: 'Oops check secret key field!',
            pt: 'Oops verificar o campo da chave secreta!'
          },
          text: {
            es: 'El campo secretkey ingresado es invalido.',
            en: 'The url secretkey seems to be invalid.',
            pt: 'O campo secretkey parece ser inválido.'
          }
        },
        invalidSecretKeyLength: {
          title: {
            es: 'Oops revisa el campo secret Key!',
            en: 'Oops check secret key field!',
            pt: 'Oops verificar o campo da chave secreta!'
          },
          text: {
            es: 'El campo secretkey debe tener una longitud de 10 caracteres.',
            en: 'The secretkey field must be 10 characters long.',
            pt: 'O campo secretkey tem de ter 10 caracteres.'
          }
        }
      },
      alreadyExistsError: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Existe otro webhook con la misma clave y misma URL.',
          en: 'Another webhook already exists with the same key and same URL.',
          pt: 'Existe outro webhook com a mesma chave e o mesmo URL.'
        }
      },
      genericError: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'It seems that an error occurred.',
          pt: 'Parece que ocorreu um erro.'
        }
      },
      genericSuccess: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'Cambios guardados con éxito',
          en: 'Changes saved successfully',
          pt: 'Alterações salvas com sucesso'
        }
      },
      deleteConfirmation: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        content: {
          es:
            'Estas por borrar un webhook, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase a webhook, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma webhook, esta ação é irreversível. Tem certeza?'
        }
      },
    },
    tabs: {
      widget: {
        es: 'Widget',
        en: 'Widget',
        pt: 'Widget'
      },
      general: {
        es: 'General',
        en: 'General',
        pt: 'Geral'
      },
      users: {
        es: 'Usuarios',
        en: 'Users',
        pt: 'Usuários'
      },
      queues: {
        es: 'Colas de atención',
        en: 'Attention queues',
        pt: 'Colas de atencion'
      },
      availability: {
        es: 'Disponibilidad',
        en: 'Availability',
        pt: 'Disponibilidade'
      },
      channels: {
        es: 'Canales',
        en: 'Channels',
        pt: 'Canais'
      },
      webhooks: {
        es: 'Webhooks',
        en: 'Webhooks',
        pt: 'Webhooks'
      },
      empathic: {
        es: 'Motor de empatía',
        en: 'Empathic engine',
        pt: 'Motor de empatia'
      },
      priorities: {
        es: 'Prioridades',
        en: 'Priorities',
        pt: 'Prioridades'
      },
      contactCenter: {
        es: 'Centro de contacto',
        en: 'Contact Center',
        pt: 'Centro de Contacto'
      }
    }
  },
  addSchedule: {
    title: {
      es: 'Crear calendario',
      en: 'Create schedule',
      pt: 'Criar cronograma'
    },
    error: {
      duplicate: {
        es: 'Ya existe un calendario con ese nombre.',
        en: 'There is already a Schedule with that name.',
        pt: 'Já há um cronograma com esse nome.'
      }
    }
  },
  addTag: {
    title: {
      es: 'Crear etiqueta',
      en: 'Create tag',
      pt: 'Criar etiqueta'
    },
    error: {
      duplicate: {
        es: 'Ya existe una etiqueta con ese nombre.',
        en: 'There is already a tag with that name.',
        pt: 'Você existe uma etiqueta com esse nome.'
      }
    }
  },
  addTagCategory: {
    title: {
      es: 'Crear categoría de etiquetas',
      en: 'Create tag category',
      pt: 'Criar categoria de tags'
    },
    error: {
      duplicate: {
        es: 'Ya existe una categoría de etiquetas con ese nombre.',
        en: 'There is already a tag category with that name.',
        pt: 'Já existe uma categoria de etiquetas com esse nome.'
      }
    }
  },
  addVariable: {
    title: {
      es: 'Crear variable',
      en: 'Create variable',
      pt: 'Criar variável'
    },
    error: {
      duplicate: {
        es: 'Ya existe una variable con ese nombre.',
        en: 'There is already a variable with that name.',
        pt: 'Já há uma variável com esse nome.'
      }
    }
  },
  botEditor: {
    errorButtons: {
      es: 'Atención: no es posible poner texto abajo de un Menú con botones',
      en:
        'Attention: it is not possible to put text below a Menu with buttons.',
      pt:
        'Atendimento: não é possível colocar texto abaixo de um Menu com botões.'
    },
    navBar: {
      title: {
        es: 'Cantidad de condiciones insuficiente',
        en: 'Insufficient amount of conditions',
        pt: 'Insuficiente quantidade de condições'
      },
      text: {
        es:
          'Debes agregar por lo menos dos condiciones para que el motor de IA funcione.',
        en: 'You must add at least two conditions for the AI ​​engine to work.',
        pt:
          'Você deve adicionar pelo menos duas condições para que o motor IA funcione.'
      },
      tooltips: {
        answer: {
          es: 'Answer Builder',
          en: 'Answer Builder',
          pt: 'Answer Builder',
        },
        play: {
          es: 'Probar bot',
          en: 'Try it',
          pt: 'Testar bot'
        },
        settings: {
          es: 'Configuración',
          en: 'Settings',
          pt: 'Configuração'
        },
        marketplace: {
          es: 'Tienda',
          en: 'Marketplace',
          pt: 'Loja'
        },
        integrations: {
          es: 'Integraciones',
          en: 'Integrations',
          pt: 'Integrações'
        },
        catalogs: {
          es: 'Catálogos',
          en: 'Catalogs',
          pt: 'Catálogos'
        },
      },
    },
    addMessage: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
    linkMessage: {
      es: 'Encadenar Mensaje',
      en: 'Link message',
      pt: 'Link mensagem'
    },
    modifyMessage: {
      es: 'Modificar',
      en: 'Modify',
      pt: 'Modificar'
    },
    cancelMessage: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    },
    missingLink: {
      es: 'Error: Todos los botones deben tener un enlace',
      en: 'Error: All buttons must have a link',
      pt: 'Erro: Todos os botões devem ter um link'
    },
    missingField: {
      es: 'Por favor ingresá',
      en: 'Please enter',
      pt: 'Por favor, digite'
    },
    addingMessage: {
      es: 'Añadiendo Mensaje',
      en: 'Adding message',
      pt: 'Adicionando mensagem'
    },
    messageType: {
      intention: {
        title: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        }
      },
      text: {
        title: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        description: {
          es: 'Respuesta del bot',
          en: 'Bot response',
          pt: 'Resposta do Bot'
        },
        descriptionError: {
          es: 'Por favor ingrese un nombre',
          en: 'Please enter a name',
          pt: 'Por favor, digite um nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        },
        example: {
          es: 'Agregar una pregunta',
          en: 'Add question',
          pt: 'Adicionar uma pergunta'
        },
        addExample: {
          es: 'Añadir',
          en: 'Add',
          pt: 'Adicionar'
        }
      },
      image: {
        title: {
          es: 'Imagen',
          en: 'Image',
          pt: 'Imagem'
        },
        description: {
          es: 'Selecciona una imagen',
          en: 'Select an image',
          pt: 'Selecione uma imagem'
        },
        error: {
          es: 'Por favor ingresa una imagen',
          en: 'Please upload an image',
          pt: 'Por favor, carregue uma imagem'
        }
      },
      media: {
        title: {
          es: 'Media',
          en: 'Media',
          pt: 'Mídia'
        },
        description: {
          es: 'Selecciona una imagen, video o documento',
          en: 'Select an image, video or document',
          pt: 'Selecione uma imagem, vídeo ou documento'
        },
        error: {
          es: 'Por favor ingresa una imagen. video o documento',
          en: 'Please upload an image, video or document',
          pt: 'Por favor, envie uma imagem, vídeo ou documento'
        },
        errorMaxSize: {
          es: 'El tamaño del archivo no debe ser superior a ',
          en: 'The file size must be no more than ',
          pt: 'O tamanho do arquivo não deve ser mais do que '
        }
      },
      buttons: {
        check: {
          es: 'Enlace o texto a enviar',
          en: 'Link or text to be sent',
          pt: 'Link ou texto a ser enviado'
        },
        isLink: {
          es: 'Es enlace?',
          en: 'Is link?',
          pt: 'É um Link?'
        },
        link: {
          es: 'Enlace',
          en: 'Link',
          pt: 'Link'
        },
        intent: {
          es: 'Carga útil',
          en: 'Payload',
          pt: 'Carga útil'
        },
        quick: {
          es: 'Respuestas relacionadas',
          en: 'Related answers',
          pt: 'Respostas relacionadas'
        },
        title: {
          es: 'Botones',
          en: 'Buttons',
          pt: 'Botões'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicionar botão'
        }
      },
      link: {
        check: {
          es: 'Enlace o texto a enviar',
          en: 'Link or text to be sent',
          pt: 'Link ou texto a ser enviado'
        },
        isLink: {
          es: 'Es enlace?',
          en: 'Is link?',
          pt: 'É um Link?'
        },
        link: {
          es: 'Enlace',
          en: 'Link',
          pt: 'Link'
        },
        intent: {
          es: 'Usar texto',
          en: 'Use text',
          pt: 'Usar texto'
        },
        title: {
          es: 'Enlace',
          en: 'Link',
          pt: 'Link'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicionar botão'
        }
      },
      youtube: {
        check: {
          es: 'Enlace o texto a enviar',
          en: 'Link or text to be sent',
          pt: 'Link ou texto a ser enviado'
        },
        isLink: {
          es: 'Es enlace?',
          en: 'Is link?',
          pt: 'É um Link?'
        },
        link: {
          es: 'Enlace',
          en: 'Link',
          pt: 'Link'
        },
        intent: {
          es: 'Usar texto',
          en: 'Use text',
          pt: 'Usar texto'
        },
        title: {
          es: 'Youtube',
          en: 'Youtube',
          pt: 'Youtube'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicionar botão'
        }
      },
      RcsSlider: {
        title: {
          es: 'Carousel',
          en: 'Carousel',
          pt: 'Carrousel'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicione o botão'
        }
      },
      RcsCard: {
        mediaError: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Error subiendo imagen',
            en: 'Error uploading image',
            pt: 'Erro ao carregar a imagem'
          }
        },
        errors: {
          text_field: {
            es: 'el texto',
            en: 'the text',
            pt: 'o texto'
          },
          buttons: {
            es: ' al menos una opción',
            en: ' at least one option',
            pt: ' pelo menos uma opção'
          },
          picture: {
            es: 'la imagen',
            en: 'the Media File',
            pt: 'o arquivo da mídia'
          },
          height: {
            es: 'la altura',
            en: 'the height',
            pt: 'a altura'
          },
          text_title: {
            es: 'el título',
            en: 'the title',
            pt: 'o título'
          },
          text_subtitle: {
            es: 'el subtítulo',
            en: 'the subtitle',
            pt: 'a legenda'
          }
        },
        title: {
          es: 'Card',
          en: 'Card',
          pt: 'Card'
        },
        picture: {
          es: 'Imagen',
          en: 'Media File',
          pt: 'Arquivo de mídia'
        },
        height: {
          es: 'Altura',
          en: 'Height',
          pt: 'Altura'
        },
        text_title: {
          es: 'Título',
          en: 'Title',
          pt: 'Título'
        },
        sizes_large: {
          es: 'Largo',
          en: 'Large',
          pt: 'Longo'
        },
        size_height: {
          es: 'Altura',
          en: 'Height',
          pt: 'Altura'
        },
        size_width: {
          es: 'Ancho',
          en: 'Width',
          pt: 'Largura'
        },
        sizes_medium: {
          es: 'Medio',
          en: 'Medium',
          pt: 'Médio'
        },
        sizes_short: {
          es: 'Corto',
          en: 'Short',
          pt: 'Curto'
        },
        text_button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        text_subtitle: {
          es: 'Subtítulo',
          en: 'Subtitle',
          pt: 'Subtítulo'
        },
        addImage: {
          es: 'Agregar imagen',
          en: 'Add image',
          pt: 'Adicionar imagem'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicione o botão'
        }
      },
      quickReply: {
        title: {
          es: 'Respuesta rápida',
          en: 'Quick reply',
          pt: 'Resposta rápida'
        },
        placeholder: {
          es: 'Respuesta rápida',
          en: 'Quick reply',
          pt: 'Resposta rápida'
        }
      },
      place: {
        title: {
          es: 'Ubicación',
          en: 'Location',
          pt: 'Localização'
        },
        description: {
          es: 'Enviar una ubicación',
          en: 'Send a location',
          pt: 'Envie uma localização'
        }
      },
      audio: {
        title: {
          es: 'Audio',
          en: 'Audio message',
          pt: 'Mensagem de áudio'
        },
        description: {
          es: 'Selecciona un audio',
          en: 'Select an audio message',
          pt: 'Selecione uma mensagem de áudio'
        }
      },
      regExp: {
        title: {
          es: 'Exp. Regulares',
          en: 'Reg. Expressions',
          pt: 'Reg. Expressões'
        },
        match: {
          description: {
            es: 'Coincidir texto',
            en: 'Match text',
            pt: 'Corresponde ao texto'
          }
        },
        regex: {
          description: {
            es: 'Expresión regular',
            en: 'Regular expression',
            pt: 'Expressão regular'
          },
          placeholder: {
            es: 'Ingrese una expresión regular',
            en: 'Enter a regular expression',
            pt: 'Digite uma expressão regular'
          },
          error: {
            es: 'Por favor ingrese una expresión regular válida',
            en: 'Please enter a valid regular expression',
            pt: 'Por favor, digite uma expressão regular válida'
          }
        }
      },
      entity: {
        title: {
          es: 'Entidades',
          en: 'Entities',
          pt: 'Entidades'
        }
      },
      extensionImage: {
        title: {
          es: '¡No se pudo cargar el archivo!',
          en: 'Could not upload the file!',
          pt: 'Não foi possível enviar o arquivo!'
        },
        message: {
          es: 'El tipo de archivo debe ser PNG, GIF, JPG, JPEG.',
          en: 'The file type must be PNG, GIF, JPG, JPEG.',
          pt: 'O tipo de arquivo deve ser PNG, GIF, JPG, JPEG.'
        }
      }
    },
    intentionsDialog: {
      title: {
        es: 'Intenciones',
        en: 'Intents',
        pt: 'Intenções'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      examples: {
        es: 'Ejemplos',
        en: 'Examples',
        pt: 'Exemplos'
      },
      accept: {
        es: 'Seleccionar',
        en: 'Select',
        pt: 'Selecionar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    },
    addVariable: {
      title: {
        es: 'Crear variable',
        en: 'Create variable',
        pt: 'Criar variável'
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'La variable se ha creado exitosamente.',
          en: 'Variable created successfully.',
          pt: 'Variável criada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'No se pudo crear la variable. Por favor, intente nuevamente.',
          en: 'The variable was not created. Please try again later.',
          pt: 'A variável não foi criada. Tente de novo mais tarde.'
        }
      }
    },
    jumpDisclaimer: {
      es: 'No es posible crear diálogos porque hay un salto',
      en: 'Unable to create dialogs because there is a jump',
      pt: 'Os diálogos não podem ser criados porque há um salto'
    },
    validationDisclaimer: {
      es: 'No es posible crear diálogos porque hay una validación',
      en: 'Unable to create dialogs because there is a validation',
      pt: 'Os diálogos não podem ser criados porque há uma validação'
    },
    addConditionalMsg: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El mensaje se ha creado exitosamente.',
          en: 'Message created successfully.',
          pt: 'Mensagem criada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'No se pudo crear el mensaje. Por favor, intente nuevamente.',
          en: 'The message was not created. Please try again later.',
          pt: 'A mensagem não foi criada. Por favor, tente novamente.'
        }
      }
    },
    editConditionalMsg: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El mensaje se ha actualizado exitosamente.',
          en: 'Message updated successfully.',
          pt: 'Mensagem atualizada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'No se pudo actualizar el mensaje. Por favor, intente nuevamente.',
          en: 'The message was not updated. Please try again later.',
          pt: 'A mensagem não foi atualizada. Por favor, tente novamente.'
        }
      }
    }
  },
  integrations: {
    accept: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
  },
  virtualAssistant: {
    es: 'Asistente Virtual',
    en: 'Virtual Assistant',
    pt: 'Assistente Virtual'
  },
  intentions: {
    title: {
      es: 'Intenciones:',
      en: 'Intents:',
      pt: 'Intenções:'
    }
  },
  answers: {
    title: {
      es: 'Entrenamiento de respuesta',
      en: 'Answer training',
      pt: 'Treinamento de resposta'
    },
    add: {
      es: 'Nuevo +',
      en: 'New +',
      pt: 'Novo +',
    },
    edit: {
      es: 'Editar',
      en: 'Edit',
      pt: 'Editar'
    },
    settings: {
      es: 'Configuraciones',
      en: 'Settings',
      pt: 'Configurações'
    },
    delete: {
      es: 'Eliminar esta respuesta',
      en: 'Delete this answer',
      pt: 'Eliminar esta resposta'
    },
    progressBar: {
      es: 'Progreso del entrenamiento',
      en: 'Training progress',
      pt: 'Progresso do treinamento'
    },
    addQuestions: {
      es: 'Agregar preguntas de entrenamiento',
      en: 'Add training questions',
      pt: 'Adicionar perguntas de treinamento'
    },
    placeholderType: {
      es: 'Escribe una pregunta',
      en: 'Type a question',
      pt: 'Digite uma pergunta'
    },
    trainedQuestions: {
      es: 'Preguntas entrenadas',
      en: 'Trained questions',
      pt: 'Perguntas treinadas'
    },
    buttonTrainedSelectAll: {
      es: 'Seleccionar todo',
      en: 'Select all',
      pt: 'Seleccione tudo'
    },
    buttonTrainedDelete: {
      es: 'Eliminar seleccionados',
      en: 'Delete selected',
      pt: 'Eliminar selecionados'
    },
    buttonTrainedMove: {
      es: 'Mover a otra respuesta',
      en: 'Move to another answer',
      pt: 'Mudar para outra resposta'
    },
    dialogConfirmation: {
      title: {
        es: 'Confirmar',
        en: 'Confirm',
        pt: 'Confirmar'
      },
      text: {
        es: '¿Desea eliminar las preguntas seleccionadas?',
        en: 'Do you want to delete selected questions?',
        pt: 'Quer eliminar perguntas seleccionadas?'
      },
      yes: {
        es: 'Si',
        en: 'Yes',
        pt: 'Sim'
      },
      no: {
        es: 'No',
        en: 'No',
        pt: 'Não'
      }
    },
    dialogMoveConfirmation: {
      text: {
        es: 'Desea mover las preguntas seleccionadas, a la respuesta:',
        en: 'You want to move the selected questions to the answer:',
        pt: 'Você deseja mover as perguntas selecionadas para a resposta:'
      }
    },
    dialogMoveQuestions: {
      title: {
        es: 'Elija su respuesta',
        en: 'Choose your answer',
        pt: 'Escolha sua resposta'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      action: {
        es: 'Acciones',
        en: 'Actions',
        pt: 'Ações'
      },
      tooltipMove: {
        es: 'Pasar a esta respuesta',
        en: 'Move to this answer',
        pt: 'Passar para esta resposta'
      },
    },
    botResponse: {
      es: 'Respuesta del Bot',
      en: 'Bot response',
      pt: 'Resposta do Bot'
    },
    allAnswers: {
      es: 'Todas las respuestas',
      en: 'All answers',
      pt: 'Todas as respostas'
    },
    modal: {
      contentDelete: {
        es:
          'Si borras esta respuesta se borrarán todos las respuestas asociadas. Esta acción es irreversible.',
        en:
          'If you delete this answer, all associated answers will be deleted. This action is irreversible.',
        pt:
          'Se você apagar esta resposta, todas as respostas associadas serão apagadas. Esta ação é irreversível.'
      },
      yes: {
        es:
          'Sí, borrar esta respuesta',
        en:
          'Yes, delete this answer',
        pt:
          'Sim, exclua esta resposta'
      },
      readOnly: {
        popUpTitle: {
          es: 'Ver Respuesta (solo lectura)',
          en: 'View Answer (read-only)',
          pt: 'Ver Resposta (somente leitura)',
        }
      },
      titleDelete: {
        es: 'Eliminar respuesta',
        en: 'Delete answer',
        pt: 'Remover resposta'
      },
      titleEdit: {
        es: 'Editar respuesta',
        en: 'Edit answer',
        pt: 'Editar resposta'
      },
      titleCreate: {
        es: 'Nueva respuesta',
        en: 'New answer',
        pt: 'Nova resposta'
      },
      name: {
        es: 'Nombre de la respuesta',
        en: 'Answer name',
        pt: 'Nome da resposta'
      },
      themes: {
        es: 'Temas',
        en: 'Themes',
        pt: 'Temas'
      },
      buttons: {
        create: {
          es: 'añadir',
          en: 'Add',
          pt: 'Adicionar'
        },
        edit: {
          es: 'Modificar',
          en: 'Modify',
          pt: 'Modificar'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'Error al actualizar la respuesta. Por favor, intente mas tarde.',
          en: 'Error updating answer. Please try again later.',
          pt: 'Erro ao atualizar a resposta. Por favor, tente novamente mais tarde.'
        }

      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Respuesta actualizado correctamente',
          en: 'Answer updated successfully',
          pt: 'Resposta atualizada com sucesso'
        }
      },
      errorCreate: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'Error al crear la respuesta. Por favor, intente mas tarde.',
          en: 'Error creating response. please try again later.',
          pt: 'Erro ao criar resposta. por favor, tente novamente mais tarde.'
        }

      },
      successCreate: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Respuesta creada correctamente',
          en: 'Answer created successfully',
          pt: 'Resposta criada com sucesso'
        }
      },
      successDelete: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Respuesta borrada correctamente',
          en: 'Answer deleted successfully',
          pt: 'Resposta eliminada com sucesso'
        }
      },
    }
  },
  answersHistory: {
    conditionsSyncPopup: {
      title: {
        es: 'Condiciones referenciadas',
        en: 'Referenced conditions',
        pt: 'Condições referenciadas'
      },
      message: {
        es: 'Parece que una o más condiciones de catálogo asociadas por referencia fueron modificadas de forma posterior a esta versión de respuesta. Por favor seleccione la acción a realizar.',
        en: 'One or more catalog conditions associated by reference were modified after this version. Please select the actions to perform.',
        pt: 'Parece que uma ou mais condições de catálogo associadas por referência foram modificadas após esta versão da resposta. Selecione a ação a ser executada.'
      },
      tooltip: {
        es: 'La opción "Mantener la referencia y sincronizar" mantiene la referencia y sicroniza el contenido. La opción "Romper referenica" mantiene la versión vieja y rompe la referencia a la condición del catálogo.',
        en: 'The "Keep reference and synchronize" option keeps the reference and synchronizes the content. The "Break reference" option keeps the old version and breaks the reference to the catalog condition.',
        pt: 'A opção "Manter referência e sincronizar" preserva a referência e sincroniza o conteúdo. A opção "Quebrar Referência" mantém a versão anterior e quebra a referência para a condição do catálogo.'
      },
      BREAK_REFERENCE: {
        es: 'Romper referencia',
        en: 'Break reference',
        pt: 'Quebrar Referência'
      },
      SYNC_UP: {
        es: 'Mantener la referencia y sincronizar',
        en: 'Keep reference and synchronize',
        pt: 'Manter referência e sincronizar'
      },
      deleted: {
        es: '(Eliminada del catálogo)',
        en: '(Removed from the catalog)',
        pt: '(Retirado do catálogo)'
      },
      accept: {
        es: 'Restaurar',
        en: 'Restore',
        pt: 'Restaurar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
    },
    drawer: {
      successRestoreTraining: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Entrenamiento restaurado correctamente',
          en: 'Training restored successfully',
          pt: 'Treinamento restaurado com sucesso'
        }
      },
      successRestoreAnswerContent: {
        text: {
          es: 'Contenido de la respuesta restaurado correctamente',
          en: 'Answer content restored successfully',
          pt: 'Conteúdo da resposta restaurado com sucesso'
        }
      },
      successRestoreAll: {
        text: {
          es: 'Contenido de la respuesta y entrenamiento restaurado correctamente',
          en: 'Answer content and training restored successfully',
          pt: 'Conteúdo da resposta e treinamento restaurado com sucesso'
        },
      },
      errorRestoreTraining: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'Error al restaurar el entrenamiento. Por favor, intente mas tarde.',
          en: 'Error restoring training. Please try again later.',
          pt: 'Erro ao restaurar o treinamento. Por favor, tente novamente mais tarde.'
        }
      },
      errorRestoreAnswerContent: {
        text: {
          es: 'Error al restaurar el contenido de la respuesta. Por favor, intente mas tarde.',
          en: 'Error restoring answer content. Please try again later.',
          pt: 'Erro ao restaurar o conteúdo da resposta. Por favor, tente novamente mais tarde.'
        },
      },
      errorRestoreAll: {
        text: {
          es: 'Error al restaurar el contenido de la respuesta y el entrenamiento. Por favor, intente mas tarde.',
          en: 'Error restoring answer content and training. Please try again later.',
          pt: 'Erro ao restaurar o conteúdo da resposta e o treinamento. Por favor, tente novamente mais tarde.'
        },
      },
      activityLables: {
        restore: {
          es: 'Restauración',
          en: 'Restoration',
          pt: 'Restauração'
        },
        answer: {
          es: 'Respuesta',
          en: 'Answer',
          pt: 'Resposta',
        },
        training: {
          es: 'Entrenando',
          en: 'Training',
          pt: 'Treinamento',
        }
      },
      optionsRestore: {
        restoreAll: {
          es: 'Restaurar todo',
          en: 'Restore all',
          pt: 'Restaurar tudo'
        },
        restoreTraining: {
          es: 'Restaurar entrenamiento',
          en: 'Restore training',
          pt: 'Restaurar treinamento'
        },
        restoreAnswerContent: {
          es: 'Restaurar contenido de respuesta',
          en: 'Restore answer content',
          pt: 'Restaurar conteúdo de resposta'
        }
      },
      dialogRestoreOnlyTraining: {
        text: {
          es: 'IMPORTANTE: Al restaurar preguntas entrenadas, es posible que exista una o varias respuestas con preguntas similares que hayas movido o eliminado en alguna ocasión.',
          en: 'IMPORTANT: When restoring trained questions, it is possible that there may be one or more answers with similar questions that you have moved or deleted on occasion.',
          pt: 'IMPORTANTE: Ao restaurar perguntas treinadas, é possível que haja uma ou mais respostas com perguntas similares que você tenha movido ou excluído ocasionalmente.'
        }
      },
      dialogRestoreAll: {
        text: {
          es: 'IMPORTANTE: Al restaurar una respuesta, es posible que exista una o varias respuestas con preguntas similares que hayas movido o eliminado en alguna ocasión.',
          en: 'IMPORTANT: When restoring an answer, it is possible that there may be one or more answers with similar questions that you have moved or deleted on occasion.',
          pt: 'IMPORTANTE: Ao restaurar uma resposta, é possível que haja uma ou mais respostas com perguntas similares que você tenha movido ou excluído ocasionalmente.'
        }
      },
      noData: {
        es: 'Sin datos',
        en: 'No data',
        pt: 'Sem dados'
      },
      openButton: {
        es: 'Historial',
        en: 'History',
        pt: 'Histórico'
      },
      backPreview: {
        es: 'Volver a versión actual',
        en: 'Back to current version',
        pt: 'Voltar para a versão atual'
      },
      title: {
        es: 'Historial de respuestas',
        en: 'Answer history',
        pt: 'Histórico de respostas'
      },
      loadError: {
        title: {
          es: 'Oops',
          en: 'Oops',
          pt: 'Opa'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.',
        }
      }
    },
  },
  catalogs: {
    title: {
      es: 'Catálogos',
      en: 'Catalogs',
      pt: 'Catálogos'
    },
    tabs: {
      variables: {
        es: 'Variables',
        en: 'Variables',
        pt: 'Variáveis'
      },
      schedules: {
        es: 'Calendarios',
        en: 'Schedules',
        pt: 'Cronogramas'
      },
      tags: {
        es: 'Tags',
        en: 'Tags',
        pt: 'Tags'
      },
      conditions: {
        es: 'Condiciones',
        en: 'Conditions',
        pt: 'Condições'
      },
      proactiveRules: {
        es: 'Reglas Proactivas',
        en: 'Proactive Rules',
        pt: 'Regras Proativas',
      },
      tagCategories: {
        es: 'Categorías de etiquetas',
        en: 'Tag categories',
        pt: 'Categorias de etiquetas',
      }
    }
  },
  jumpError: {
    es: 'Debe elegir el diálogo del salto',
    en: 'You must choose the jump dialog',
    pt: 'Você deve escolher o diálogo de salto'
  },
  botMessageValidation: {
    validateMessage: {
      es: 'Validar respuesta',
      en: 'Validate response',
      pt: 'Validar a resposta'
    },
    tooltip: {
      es: 'Permite validar respuestas',
      en: 'Allow to validate responses',
      pt: 'Permite validar as respostas'
    },
    form: {
      name: {
        label: {
          es: 'Nombre de validación',
          en: 'Validation name',
          pt: 'Nome da validação'
        },
        placeholder: {
          es: 'Ingrese nombre de validación',
          en: 'Insert validation name',
          pt: 'Insira o nome de validação'
        },
        error: {
          es: "El campo 'Nombre de validación' es requerido.",
          en: "Field 'Validation name' is required.",
          pt: "Campo 'Nome de validação' é necessário."
        }
      },
      question: {
        label: {
          es: 'Pregunta',
          en: 'Question',
          pt: 'Pergunta'
        },
        placeholder: {
          es: 'Ingrese una pregunta',
          en: 'Insert a question',
          pt: 'Insira uma pergunta'
        },
        error: {
          es: 'El campo "Pregunta" es requerido',
          en: 'Field "Question" is required.',
          pt: 'O campo "Pergunta" é necessário.'
        }
      },
      waitTime: {
        label: {
          es: 'Tiempo de espera (minutos)',
          en: 'Wait time (minutes)',
          pt: 'Tempo de espera (minutos)'
        },
        placeholder: {
          es: 'Ingrese el tiempo de espera',
          en: 'Insert wait time',
          pt: 'Inserir o tempo de espera'
        },
        error: {
          es: 'El campo "Tiempo de espera" es requerido',
          en: 'Field "Wait time" is required.',
          pt: 'Campo "Tempo de espera" é necessário.'
        }
      },
      waitTime2: {
        label: {
          es: 'Acción luego de derivar a agente (minutos)',
          en: 'Send question after (minutes):',
          pt: 'Enviar pergunta depois (minutos):'
        },
        placeholder: {
          es: 'Ingrese el tiempo de espera',
          en: 'Insert wait time',
          pt: 'Inserir o tempo de espera'
        },
        error: {
          es: 'El campo "Tiempo de espera" es requerido',
          en: 'Field "Wait time" is required.',
          pt: 'Campo "Tempo de espera" é necessário.'
        }
      },
      replyWhileWaitingForAgent: {
        label: {
          es: 'Responder cuando el usuario envía una pregunta',
          en: 'Respond when user submits a question',
          pt: 'Responder quando o usuário enviar uma pergunta',
        },
        placeholder: {
          es: 'El bot respondera al usuario cada vez que este envíe una consulta y se esté esperando por un agente',
          en: 'The bot will respond to the user every time he sends a query and is waiting for an agent',
          pt: 'O bot responderá ao usuário toda vez que ele enviar uma consulta e estiver esperando por um agente',
        },
      },
      answers: {
        title: {
          es: 'Configuración de respuestas',
          en: 'Responses settings',
          pt: 'Configuração das respostas'
        },
        table: {
          answer: {
            es: 'Respuesta',
            en: 'Response',
            pt: 'Resposta'
          },
          showOption: {
            es: 'Opción a mostrar',
            en: 'Show option',
            pt: 'Mostrar opção'
          },
          action: {
            es: 'Acción',
            en: 'Action',
            pt: 'Ação'
          },
          actionsPlaceholder: {
            es: 'Seleccionar acción',
            en: 'Select action',
            pt: 'Selecionar ação'
          },
          positive: {
            es: 'Respuesta Positiva',
            en: 'Positive Response',
            pt: 'Resposta Positiva'
          },
          negative: {
            es: 'Respuesta Negativa',
            en: 'Negative Response',
            pt: 'Resposta Negativa'
          },
          actionsErrorMsg: {
            key: {
              es: 'El campo "Acción" es requerido.',
              en: 'Field "Action" is required.',
              pt: 'Campo "Ação" é necessário.'
            },
            args: {
              es: 'El campo "Parámetros" es requerido.',
              en: 'Field "Parameters" is required.',
              pt: 'Campo "Parâmetros" é necessário.'
            }
          },
          positiveOptions: {
            option1: {
              es: 'Sí',
              en: 'Yes',
              pt: 'Sim'
            },
            option3: {
              es: '👍',
              en: '👍',
              pt: '👍'
            }
          },
          negativeOptions: {
            option1: {
              es: 'No',
              en: 'No',
              pt: 'Não'
            },
            option3: {
              es: '👎',
              en: '👎',
              pt: '👎'
            }
          },
          takeover: {
            es: 'Derivar a agente',
            en: 'Transfers to agent',
            pt: 'Transferência para o agente'
          },
          stopTakeoverAndJump: {
            es: 'Salto y terminar takeover',
            en: 'Jump and finish takeover',
            pt: 'Saltar e terminar takeover'
          },
          stopTakeoverAndText: {
            es: 'Texto y terminar takeover',
            en: 'Text and finish takeover',
            pt: 'Texto e terminar takeover'
          },
          stopTakeover: {
            es: 'Terminar takeover',
            en: 'Finish takeover',
            pt: 'Terminar takeover'
          },
          text: {
            es: 'Texto',
            en: 'Text',
            pt: 'Texto'
          },
          jump: {
            es: 'Salto',
            en: 'Jump',
            pt: 'Pule'
          }
        }
      },
      repeatTimes: {
        title: {
          es: '¿Preguntar nuevamente?',
          en: 'Ask again?',
          pt: 'Perguntar de novo?'
        },
        times: {
          es: 'veces',
          en: 'times',
          pt: 'vezes'
        },
        expirationTime: {
          title: {
            es: 'Tiempo de expiración al reenvío de la pregunta de validación',
            en: 'Expiration time when resending the validation question',
            pt: 'Tempo de expiração ao reenviar a pergunta de validação'
          },
          label: {
            es: 'No volver a preguntar si pasó más de:',
            en: 'Do not ask again if passed more than:',
            pt: 'Não pergunte novamente se passou mais de:'
          },
          tooltip: {
            es: 'Tiempo que debe transcurrir desde que se envía la primera pregunta de validación para no preguntar nuevamente en caso de una respuesta inválida',
            en: 'Time that must elapse since the first validation question is sent to not ask again in case of an invalid response',
            pt: 'Tempo que deve decorrer desde que a primeira pergunta de validação é enviada para não perguntar novamente em caso de resposta inválida'
          },
        }
      },
      repeatTimes2: {
        title: {
          es:
            'En caso de no recibir la respuesta esperada, preguntar nuevamente?',
          en: 'In case the User does not respond as expected, ask again?',
          pt: 'Caso não receba a resposta esperada, pergunte de novo?'
        },
        times: {
          es: 'veces',
          en: 'times',
          pt: 'vezes'
        }
      },
      applyAll: {
        es: 'Aplicar a todas las validaciones de respuestas',
        en: 'Apply to all response validations',
        pt: 'Aplicar a todas as validações de resposta'
      },
      validationUpdate: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        text: {
          es:
            'Modificar esta validación afectará a todos los diálogos que utilizan la misma validación. ¿Cómo desea proceder?',
          en:
            'Modifying this validation will affect all dialogs that use the same validation. How do you want to proceed?',
          pt:
            'Modificar esta validação afetará todas os diálogos que usam a mesma validação. Como você deseja proceder?'
        },
        option1: {
          es: 'Actualizar validación',
          en: 'Update validation',
          pt: 'Validação de atualização'
        },
        option2: {
          es: 'Guardar como nueva validación',
          en: 'Save as a new validation',
          pt: 'Salvar como uma nova validação'
        }
      }
    },
    messages: {
      error: {
        saveValidation: {
          es:
            'Ha ocurrido un error al guardar la validación. Por favor, intente nuevamente.',
          en:
            'An error occurred while saving the validation. Please try again.',
          pt: 'Ocorreu um erro ao salvar a validação.'
        }
      }
    }
  },
  widgetPreview: {
    error: {
      title: {
        es: 'Oops',
        en: 'Oops',
        pt: 'Opa'
      },
      text: {
        es: 'Widget no existe',
        en: "Widget doesn't exists",
        pt: 'O widget não existe'
      }
    }
  },
  testGroups: {
    view: {
      description: {
        en: 'Description',
        es: 'Descripción',
        pt: 'Descrição'
      },
      errors: {
        execution: {
          empty: {
            es: 'Por favor ingresa un email.',
            en: 'Please enter an email.',
            pt: 'Por favor insira um email.'
          },
          emptyList: {
            es: 'Debe agregar emails para enviar la ejecución.',
            en: 'You must add emails to submit the execution.',
            pt: 'Você deve adicionar e-mails para enviar a execução.'
          },
          duplicate: {
            es: 'Ese email ya fue ingresado',
            en: 'That email has already been entered',
            pt: 'Esse email já foi inserido'
          },
        },
        text: {
          en: 'Please fill in this field',
          es: 'Por favor llene este campo',
          pt: 'Por favor, preencha este campo'
        },
        name: {
          en: 'Please enter a name',
          es: 'Por favor ingrese un nombre',
          pt: 'Por favor insira um nome'
        },
        description: {
          en: 'Please enter a description',
          es: 'Por favor ingrese una descripción',
          pt: 'Por favor insira uma descrição'
        }
      }
    }
  },
  marketplace: {
    title: {
      es: 'Tienda',
      en: 'Marketplace',
      pt: 'Marketplace'
    },
    tabs: {
      marketplace: {
        es: 'Tienda',
        en: 'Marketplace',
        pt: 'Marketplace'
      },
      installedApps: {
        es: 'Instaladas',
        en: 'Installed',
        pt: 'Instaladas'
      },
      installed: {
        settings: {
          title: {
            es: 'Configuración de la aplicación',
            en: 'Application settings',
            pt: 'Configurações do aplicativo'
          },
          buttonCancel: {
            es: 'Cancelar',
            en: 'Cancel',
            pt: 'Cancelar'
          },
          buttonSave: {
            es: 'Guardar',
            en: 'Save',
            pt: 'Salvar'
          }
        },
        validation: {
          title: {
            es: 'Error',
            en: 'Error',
            pt: 'Erro'
          },
          text: {
            es: 'Todos los campos son requeridos',
            en: 'All fields are required',
            pt: 'Todos os campos são obrigatórios'
          },
        },
        update: {
          success: {

            title: {
              es: 'Configuración actualizada',
              en: 'Configuration updated',
              pt: 'Configuração atualizada'
            },
            text: {
              es: 'La configuración de la aplicación ha sido actualizada',
              en: 'The application configuration has been updated',
              pt: 'A configuração da aplicação foi atualizada'
            }
          },
          error: {
            title: {
              es: 'Error al actualizar la configuración',
              en: 'Error updating configuration',
              pt: 'Erro ao atualizar a configuração'
            },
            text: {
              es: 'Ha ocurrido un error al actualizar la configuración de la aplicación',
              en: 'An error occurred while updating the application configuration',
              pt: 'Ocorreu um erro ao atualizar a configuração da aplicação'
            }
          },
        },
        title: {
          es: 'Aplicaciones instaladas',
          en: 'Installed apps',
          pt: 'Aplicações instaladas'
        },
        text: {
          es: 'Habilita y configura tus aplicaciones',
          en: 'Enable and configure your apps',
          pt: 'Habilite e configure suas aplicações'
        }
      },
      available: {
        title: {
          es: 'Aplicaciones disponibles',
          en: 'Available apps',
          pt: 'Aplicações disponíveis'
        },
        text: {
          es: 'Instala y configura tus aplicaciones',
          en: 'Install and configure your apps',
          pt: 'Instale e configure suas aplicações'
        },
        install: {
          es: 'Instalar',
          en: 'Install',
          pt: 'Instalar'
        },
        installed: {
          es: 'Instalada',
          en: 'Installed',
          pt: 'Instalada'
        },
        installedSuccess: {
          title: {
            es: 'Éxito!',
            en: 'Success!',
            pt: 'Sucesso!'
          },
          text: {
            en: 'Setup Complete! Your app is installed and configured.',
            es: '¡Configuración completada! Su aplicación está instalada y configurada.',
            pt: 'Instalação concluída! Seu aplicativo está instalado e configurado.'
          },
        },
        installedError: {
          title: {
            es: 'Error al instalar',
            en: 'Error installing',
            pt: 'Erro ao instalar'
          },
          text: {
            es: 'Hubo un error al instalar la aplicación',
            en: 'There was an error installing the app',
            pt: 'Houve um erro ao instalar a aplicação'
          },
        },
      }
    }
  },
  darkMode: {
    title: {
      es: 'Colores del modo oscuro',
      en: 'Dark mode colors',
      pt: 'Cores do modo escuro'
    },
    activeDM: {
      es: 'Activar tema oscuro para widget y chat',
      en: 'Activate dark theme for widget and chat',
      pt: 'Ative o tema escuro para widget e bate-papo'
    },
    bubbleTitle: {
      es: 'Burbuja de widgets',
      en: 'Widget bubble',
      pt: 'Bolha do widget'
    },
    bubbleBackground: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    bubbleText: {
      es: 'Texto',
      en: 'Text',
      pt: 'Texto'
    },
    headerTitle: {
      es: 'Encabezamiento',
      en: 'Header',
      pt: 'Cabeçalho'
    },
    headerBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    headerTextColor: {
      es: 'Texto',
      en: 'Text',
      pt: 'Cor do texto'
    },
    chatWindowTitle: {
      es: 'Ventana de chat',
      en: 'Chat window',
      pt: 'Janelas de bate-papo'
    },
    chatWindowBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    chatWindowSystemTextColor: {
      es: 'Texto de información del sistema',
      en: 'System information text',
      pt: 'Texto de informações do sistema'
    },
    botMessagesTitle: {
      es: 'Mensajes del bots',
      en: 'Bot messages',
      pt: 'Mensagens de bot'
    },
    botMessagesBackgroundColor: {
      es: 'Mensajes del bots',
      en: 'Bot messages',
      pt: 'Mensagens de bot'
    },
    botMessagesTextColor: {
      es: 'Color de texto',
      en: 'Text color',
      pt: 'Cor do texto'
    },
    botMessagesLinkColor: {
      es: 'Color de enlace',
      en: 'Link color',
      pt: 'Cor do link'
    },
    userMessagesTitle: {
      es: 'Mensajes de usuario',
      en: 'User messages',
      pt: 'Mensagens do usuário'
    },
    userMessagesBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    userMessagesTextColor: {
      es: 'Color de texto',
      en: 'Text color',
      pt: 'Cor do texto'
    },
    buttonsTitle: {
      es: 'Botones de chat',
      en: 'Chat buttons',
      pt: 'Botões de bate-papo'
    },
    buttonsBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    buttonsTextColor: {
      es: 'Color de texto',
      en: 'Text color',
      pt: 'Cor do texto'
    },
    buttonsBorderColor: {
      es: 'Borde',
      en: 'Border',
      pt: 'Fronteira'
    },
    feedbackTitle: {
      es: 'Comentarios (pulgar arriba y abajo)',
      en: 'Feedback (thumb up and down)',
      pt: 'Feedback (polegar para cima e para baixo)'
    },
    feedbackBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    feedbackThump: {
      es: 'Pulgar',
      en: 'Thumb',
      pt: 'Dedão'
    },
    textInputTitle: {
      es: 'Entrada de texto',
      en: 'Text input',
      pt: 'Entrada de texto'
    },
    textInputBackgroundColor: {
      es: 'Color de fondo',
      en: 'Background color',
      pt: 'Cor de fundo'
    },
    textInputTextColor: {
      es: 'Color de texto',
      en: 'Text color',
      pt: 'Cor do texto'
    },
    textInputBorderColor: {
      es: 'Borde',
      en: 'Border',
      pt: 'Fronteira'
    },
    textInputIcons: {
      es: 'Iconos',
      en: 'Icons',
      pt: 'Ícones'
    },
    primarySystemButtonTitle: {
      es: 'Botones del sistema primario',
      en: 'Primary system buttons',
      pt: 'Botões do sistema primário'
    },
    primarySystemButtonBack: {
      es: 'Color de fondo',
      en: 'Background',
      pt: 'Cor de fundo'
    },
    primarySystemButtonText: {
      es: 'Texto',
      en: 'Text',
      pt: 'Texto'
    },
    primarySystemButtonBorder: {
      es: 'Borde',
      en: 'Border',
      pt: 'Fronteira'
    },
    secondaySystemButtonTitle: {
      es: 'Botones del sistema secundario',
      en: 'Secondary system buttons',
      pt: 'Botões do sistema secundário'
    },
    popUpMessageTitle: {
      es: 'Mensajes pop-up',
      en: 'Pop up messages',
      pt: 'Mensagens pop-up'
    },
  },
  versions: {
    table: {  
      tag: {
        es: 'Etiqueta',
        en: 'Tag',
        pt: 'Etiqueta'
      },
      status: {
        es: 'Estado',
        en: 'Status',
        pt: 'Status'
      },
      _createdAt: {
        es: 'Creación',
        en: 'Created on',
        pt: 'Criado em'
      },
      edit: {
        es: 'Editar',
        en: 'Edit',
        pt: 'Editar'
      }
    },
  }
}
