<template>
  <tr
    ref="tableTr"
    :class="[
      `tr-table-state-${state}`,
      {
        'is-selected': isSelected,
        selected: data,
        'tr-expandedx': expanded,
        hoverFlat: $parent.hoverFlat
      }
    ]"
    class="tr-values vs-table--tr"
    @dblclick="dblclicktr"
    @click="clicktr"
  >
    <td
      v-if="$parent.multiple || $slots.expand"
      :class="{ 'active-expanded': expanded }"
      class="td-check"
    >
      <vx-checkbox
        v-if="$parent.multiple"
        :checked="isSelected"
        size="small"
        @change="handleCheckbox"
      />

      <vs-icon
        v-if="$slots.expand"
        icon="icon-expand"
        icon-pack="symbolicon-font"
      />
    </td>
    <slot />
  </tr>

  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <tr-expand
    v-if="$slots.expand && expanded"
    :colspan="5"
  >
    <slot name="expand" />
  </tr-expand>
</template>

<script>
import { defineAsyncComponent } from 'vue';
export default {
  name: 'VsTr',
  compatConfig: { WATCH_ARRAY: false },
  components: {
    trExpand: defineAsyncComponent(() => import('./vsTrExpand.vue'))
  },
  props: {
    state: {
      type: String,
      default: null
    },
    data: {
      default: null,
      type: [String, Number, Object]
    }
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    isSelected() {
      if (this.$parent.multiple && this.$parent.value) {
        return this.data ? this.$parent.value.includes(this.data) : false
      } else {
        return this.data ? this.$parent.value == this.data : false
      }
    }
  },
  watch: {
    '$parent.datax': {
      deep: true,
      handler() {
        this.expanded = false
      }
    }
  },
  created() {
    if (this.$slots.expand) this.$parent.hasExpadableData = true
  },
  methods: {
    handleCheckbox() {
      this.$parent.handleCheckbox(this.data)
    },
    clicktr() {
      this.$parent.clicktr(this.data, true)

      if (this.$slots.expand) {
        this.expanded = !this.expanded
      }
    },
    dblclicktr() {
      this.$parent.dblclicktr(this.data, true)
    },
  }
}
</script>

<style scoped>
.is-selected {
  box-sizing: border-box;
  background-color: rgba(var(--vs-primary), 0.05);
  -webkit-box-shadow: 0 0 1px 0 rgba(var(--vs-primary), 1) !important;
  box-shadow: 0 0 1px 0 rgba(var(--vs-primary), 1) !important;
  border-bottom: 0 !important;
}
.selected {
  cursor: pointer;
}
</style>