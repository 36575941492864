import { createRouter, createWebHashHistory } from 'vue-router'
import store from './store/store'

import { USER_ROLES } from './models/Roles/UserRoles'
import { User } from './models/User'

const navbarTitleManager = (langPathTitle) => {
  return {
    beforeEnter: (to, from, next) => {
      if (langPathTitle) store.dispatch('navbar/NAVBAR_SET_PAGE_TITLE', {
        pathTitle: langPathTitle,
        lang: store.state.languageSelected
      })
      next()
    },
  }
}

const ALLOWED_ROLES = {
  DEFAULT: [
    USER_ROLES.SYS_ADMIN,
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.EDITOR,
    USER_ROLES.AGENT,
    USER_ROLES.ROLES_MANAGER,
    USER_ROLES.GUEST
  ],
  COMPANIES: [USER_ROLES.SYS_ADMIN],
  ATTENTION_REQUESTS: [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.AGENT
  ],
  ATTENTION_ALL_CHATS: [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.AGENT,
    USER_ROLES.GUEST
  ],
  QUEUES: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
  ATTENTION_REQUESTS_SETTINGS: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
  BOT_MAKER: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR, USER_ROLES.GUEST],
  METRICS: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.GUEST],
  METRICS_QUERY_BUILDER: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
  AUDIT: [USER_ROLES.SUPER_ADMIN],
  ROLES: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
  USERS: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
  THEMES: [USER_ROLES.SUPER_ADMIN],
  SSO: [USER_ROLES.SUPER_ADMIN],
  IP_RANGES: [USER_ROLES.SUPER_ADMIN],
  NLU: [USER_ROLES.SUPER_ADMIN],
  CLUSTERING: [USER_ROLES.SUPER_ADMIN, USER_ROLES.GUEST],
  FEEDBACK: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.GUEST],
  CREATE_BOT: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
  INTEGRATIONS: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR, USER_ROLES.GUEST],
  CATALOGS: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR, USER_ROLES.GUEST],
  ANSWERS_VIEW: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR, USER_ROLES.GUEST],
  RECOVERY: [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.EDITOR,
    USER_ROLES.AGENT,
    USER_ROLES.ROLES_MANAGER
  ],
  ACCOUNT_SETUP: [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.EDITOR,
    USER_ROLES.AGENT,
    USER_ROLES.ROLES_MANAGER
  ],
}

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('./views/home/Main.vue'),
      name: 'home',
      meta: {
        canAccess: ALLOWED_ROLES.DEFAULT,
        staffManagement: {
          canAccess: ['*']
        }
      },
      beforeEnter(to, from, next) {
        if (store.state.changePassword) {
          next({
            path: '/pages/change-password'
          })
        } else if (localStorage.sessionStatus !== 'true') {
          next({
            path: '/pages/login'
          })
        } else {
          next()
        }
      },
      children: [
        {
          path: 'companies',
          component: () => import('./views/home/companies/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.COMPANIES,
            staffManagement: {
              canAccess: []
            }
          },
          children: [
            {
              path: '',
              component: () => import('./views/home/companies/Companies.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.COMPANIES,
                staffManagement: {
                  canAccess: []
                }
              }
            },
            {
              path: 'add',
              component: () =>
                import('./views/home/companies/CreateCompany.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.COMPANIES,
                staffManagement: {
                  canAccess: []
                }
              }
            }
          ]
        },
        {
          path: 'attention-requests',
          name: 'attention-requests',
          component: () => import('./views/home/attention-requests/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.ATTENTION_REQUESTS,
            staffManagement: {
              canAccess: ['konecta.pending_chats.list']
            }
          }
        },
        {
          path: 'all-chats',
          name: 'all-chats',
          component: () => import('./views/home/all-chats/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.ATTENTION_ALL_CHATS,
            staffManagement: {
              canAccess: ['konecta.all_chats.list']
            }
          }
        },
        {
          path: 'queues',
          name: 'queues',
          component: () => import('./views/home/queues/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.QUEUES,
            staffManagement: {
              canAccess: ['konecta.queues.list']
            }
          }
        },
        {
          path: 'attention-requests-settings',
          name: 'attention-requests-settings',
          component: () =>
            import('./views/home/attention-requests-settings/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.ATTENTION_REQUESTS_SETTINGS,
            staffManagement: {
              canAccess: ['konecta.pending_chats_settings.view']
            }
          }
        },
        {
          path: 'bot-maker',
          component: () => import('./views/home/bot-maker/Main.vue'),
          redirect: '/bot-maker/bots',
          meta: {
            canAccess: ALLOWED_ROLES.BOT_MAKER,
            staffManagement: {
              canAccess: ['konecta.bots.list']
            }
          },
          children: [
            {
              path: 'bots',
              name: 'bots',
              component: () =>
                import('./views/home/bot-maker/bots-view/Bots.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.BOT_MAKER,
                staffManagement: {
                  canAccess: ['konecta.bots.list']
                }
              }
            },
            {
              path: 'add',
              name: 'create-bot',
              component: () =>
                import('./views/home/bot-maker/add-bot/AddBot.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.CREATE_BOT,
                staffManagement: {
                  canAccess: ['konecta.bots.add']
                }
              }
            },
            {
              path: 'bot/:bot/edit',
              name: 'edit-bot',
              component: () =>
                import('./views/home/bot-maker/add-bot/AddBot.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.BOT_MAKER,
                staffManagement: {
                  canAccess: [
                    'konecta.bots.add',
                    'konecta.bot_maker.edit_bot_settings'
                  ]
                }
              }
            },
            {
              path: 'bot/:bot/marketplace/:action/:appKey',
              name: 'bot-marketplace-action',
              ...navbarTitleManager('marketplace.title'),
              component: () =>
                import('@/views/home/bot-maker/bot-marketplace/AppConfiguration.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.BOT_MAKER,
              }
            },
            {
              path: 'bot/:bot/marketplace',
              name: 'bot-marketplace',
              ...navbarTitleManager('marketplace.title'),
              component: () =>
                import(
                  './views/home/bot-maker/bot-marketplace/BotMarketplace.vue'
                ),
              meta: {
                canAccess: ALLOWED_ROLES.BOT_MAKER
              }
            },
            {
              path: 'bot/:bot/integrations',
              name: 'integrations',
              component: () =>
                import('./views/home/integrations/Integrations.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.INTEGRATIONS,
                staffManagement: {
                  canAccess: ['konecta.bot_maker.integrations']
                }
              }
            },
            {
              path: 'bot/:bot/catalogs',
              name: 'catalogs',
              component: () => import('./views/home/catalogs/Catalogs.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.CATALOGS,
                staffManagement: {
                  canAccess: ['konecta.bot_maker.catalogs']
                }
              }
            },
            {
              path: 'bot/:bot/answers-view',
              name: 'answers-view',
              component: () =>
                import('./views/home/answers-view/AnswersView.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.ANSWERS_VIEW,
                staffManagement: {
                  canAccess: ['konecta.bot_maker.tree_view']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/answers-view/answers-predefined',
              name: 'answers-predefined-answers',
              ...navbarTitleManager('answersPredefined.navBarTitle'),
              component: () =>
                import('./views/home/answers-predefined/AnswersPredefined.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.ANSWERS_VIEW,
                staffManagement: {
                  canAccess: ['konecta.bot_maker.tree_view']
                }
              }
            }
          ]
        },
        {
          path: 'query-builder',
          component: () =>
            import('./views/home/metrics/query-builder/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.METRICS_QUERY_BUILDER,
            staffManagement: {
              canAccess: ['konecta.analytics.general']
            }
          }
        },
        {
          path: 'answer-analytics',
          component: () =>
            import('./views/home/metrics/query-builder/AnswerAnalytics.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.METRICS,
            staffManagement: {
              canAccess: ['konecta.analytics.general']
            }
          }
        },
        {
          path: 'answer-metric-analytics/:id',
          component: () =>
            import(
              './views/home/metrics/query-builder/AnswerMetricAnalytics.vue'
            ),
          meta: {
            canAccess: ALLOWED_ROLES.METRICS,
            staffManagement: {
              canAccess: ['konecta.analytics.general']
            }
          }
        },
        {
          path: 'answer-analytics/:id',
          component: () =>
            import('./views/home/metrics/query-builder/Show.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.METRICS,
            staffManagement: {
              canAccess: ['konecta.analytics.general']
            }
          }
        },
        {
          name: 'audit',
          path: 'audit',
          component: () => import('./views/home/audit/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.AUDIT,
            staffManagement: {
              canAccess: ['konecta.audit.list']
            }
          }
        },
        {
          name: 'answerHistory',
          path: 'answerHistory',
          component: () => import('./views/home/answerHistory/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.AUDIT,
            staffManagement: {
              canAccess: ['konecta.audit.list']
            }
          }
        },
        {
          path: 'roles',
          component: () => import('./views/home/staffRoles/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.ROLES,
            staffManagement: {
              canAccess: ['konecta.roles.list']
            }
          },
          children: [
            {
              path: '',
              component: () => import('./views/home/staffRoles/StaffRoles.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.ROLES,
                staffManagement: {
                  canAccess: ['konecta.roles.list']
                }
              }
            },
            {
              path: 'add',
              component: () =>
                import('./views/home/staffRoles/AddStaffRole.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.ROLES,
                staffManagement: {
                  canAccess: ['konecta.roles.add']
                }
              }
            },
            {
              path: ':staffRoleId/edit',
              component: () =>
                import('./views/home/staffRoles/AddStaffRole.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.ROLES,
                staffManagement: {
                  canAccess: ['konecta.roles.edit']
                }
              }
            }
          ]
        },
        {
          path: 'users',
          component: () => import('./views/home/users/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.USERS,
            staffManagement: {
              canAccess: ['konecta.users.list']
            }
          },
          children: [
            {
              path: '',
              component: () => import('./views/home/users/Users.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.USERS,
                staffManagement: {
                  canAccess: ['konecta.users.list']
                }
              }
            },
            {
              path: 'add',
              component: () => import('./views/home/users/AddUser.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.USERS,
                staffManagement: {
                  canAccess: ['konecta.users.add']
                }
              }
            },
            {
              path: ':userId/edit',
              component: () => import('./views/home/users/AddUser.vue'),
              meta: {
                canAccess: ALLOWED_ROLES.USERS,
                staffManagement: {
                  canAccess: ['konecta.users.edit']
                }
              }
            }
          ]
        },
        {
          path: 'themes',
          component: () =>
            import('./views/home/configuration/themes/Themes.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.THEMES,
            staffManagement: {
              canAccess: ['konecta.themes.view']
            }
          }
        },
        {
          path: 'sso',
          component: () => import('./views/home/configuration/sso/Sso.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.SSO,
            staffManagement: {
              canAccess: ['konecta.sso.view']
            }
          }
        },
        {
          path: 'ip-ranges',
          component: () =>
            import('./views/home/security/ipRanges/IPRanges.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.IP_RANGES,
            staffManagement: {
              canAccess: ['konecta.security.ip_ranges']
            }
          }
        },
        {
          path: 'nlu',
          component: () =>
            import('./views/home/configuration/credentials/nlu/NLU.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.NLU,
            staffManagement: {
              canAccess: ['konecta.nlu_settings.view']
            }
          }
        },
        {
          path: 'clustering',
          component: () => import('./views/home/training/clustering/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.CLUSTERING,
            staffManagement: {
              canAccess: ['konecta.clusterization.list']
            }
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  './views/home/training/clustering/clusters-view/Clustering.vue'
                ),
              meta: {
                canAccess: ALLOWED_ROLES.CLUSTERING,
                staffManagement: {
                  canAccess: ['konecta.clusterization.list']
                }
              }
            },
            {
              path: ':clusterId/centers',
              component: () =>
                import(
                  './views/home/training/clustering/centers/ClusterCenters.vue'
                ),
              meta: {
                canAccess: ALLOWED_ROLES.CLUSTERING,
                staffManagement: {
                  canAccess: ['konecta.clusterization.list']
                }
              }
            }
          ],
          beforeEnter(to, from, next) {
            const showClustering = JSON.parse(localStorage.session).clustering
            if (showClustering) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          path: 'feedback',
          component: () => import('./views/home/training/feedback/Main.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.FEEDBACK,
            staffManagement: {
              canAccess: ['konecta.trainer.view']
            }
          }
        }
      ]
    },
    // =============================================================================
    // PAGES
    // =============================================================================
    {
      path: '/pages',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: 'login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'okta-expired',
          name: 'pageOktaExpired',
          component: () => import('@/views/pages/OktaExpired.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: '404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: '500',
          name: 'pageError500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'not-authorized',
          name: 'pageNotAuthorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'account-blocked',
          name: 'accountBlocked',
          component: () => import('@/views/pages/AccountBlocked.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'invalid-link',
          name: 'expireInvalidLink',
          component: () => import('@/views/pages/ExpireInvalidLink.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'banned',
          name: 'Banned',
          component: () => import('@/views/pages/Banned.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'recovery/:token/:roles',
          name: 'page-recovery',
          component: () => import('@/views/pages/SetPassword.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.RECOVERY,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'account-setup/:token',
          name: 'page-account-setup',
          component: () => import('@/views/pages/AccountSetup.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.ACCOUNT_SETUP,
            staffManagement: {
              canAccess: ['*']
            }
          }
        },
        {
          path: 'change-password',
          name: 'page-change-password',
          component: () => import('@/views/pages/ChangePassword.vue'),
          meta: {
            canAccess: ALLOWED_ROLES.DEFAULT,
            staffManagement: {
              canAccess: ['*']
            }
          }
        }
      ]
    },
    {
      path: '/callback',
      component: () => import('@/views/pages/SSOCallback.vue'),
      meta: {
        canAccess: ALLOWED_ROLES.DEFAULT,
        staffManagement: {
          canAccess: ['*']
        }
      }
    },
    // Wild card matching MUST ALWAYS be last route declared
    // https://v3.router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
    {
      path: '/:pathMatch(.*)*',
      redirect: '/pages/404'
    },
  ]
})

/**
 * Determine if the current user role can access to the route
 * @param roles {Array<String>}
 * @param routeTo
 * @returns {boolean}
 */
const roleCanAccess = ({ roles, staffManagementPermissions, routeTo }) => {
  if (staffManagementPermissions) {
    return (
      roles
        .concat('*')
        .filter(x => routeTo.meta.staffManagement.canAccess.includes(x))
        .length > 0
    )
  }
  return roles.filter(x => routeTo.meta.canAccess.includes(x)).length > 0
}

router.beforeEach((to, from, next) => {
  store.dispatch('navbar/NAVBAR_CLEAN_TITLE')
  if (store?.state?.session?.useStaffManagement) {
    if (
      roleCanAccess({
        roles: store.state.session.roles,
        staffManagementPermissions: true,
        routeTo: to
      })
    ) {
      return next()
    } else {
      return next('/pages/not-authorized')
    }
  }
  if (store?.state?.session?.user?.roles) {
    if (
      roleCanAccess({ roles: store.state.session.user.roles.ids, routeTo: to })
    ) {
      return next()
    } else {
      return next('/pages/not-authorized')
    }
  } else {
    const sessionStorage = JSON.parse(localStorage.getItem('session'))
    if (sessionStorage?.useStaffManagement) {
      if (
        roleCanAccess({
          roles: sessionStorage.roles,
          staffManagementPermissions: true,
          routeTo: to
        })
      ) {
        return next()
      } else {
        return next('/pages/not-authorized')
      }
    }
    if (sessionStorage?.roles) {
      const user = new User(
        sessionStorage.user.name,
        sessionStorage.user.email,
        '',
        sessionStorage.roles
      )
      if (roleCanAccess({ roles: user.roles.ids, routeTo: to })) {
        return next()
      } else {
        return next('/pages/not-authorized')
      }
    } else if (
      to.path === '/oktaCallback.html' ||
      to.path === '/callback' ||
      to.path === '/pages/okta-expired' ||
      to.path === '/pages/login' ||
      to.path === '/pages/reset-password' ||
      to.path === '/pages/invalid-link' ||
      to.path === '/pages/account-blocked' ||
      /^\/pages\/recovery\/.+/.test(to.path) ||
      /^\/pages\/account-setup\/.+/.test(to.path)
    ) {
      next()
    } else {
      next('/pages/login')
    }
  }
})

router.afterEach(() => {
  const tooltips = document.querySelectorAll('.vs-tooltip')
  tooltips.forEach(tooltip => tooltip.remove())
})

export default router
